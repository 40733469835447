import React, { useState } from 'react';
import { URL } from "../../../utils/Constants";
import { axiosPost } from "../../../utils/AxiosApi";
import { Spinner } from "reactstrap";
import { displayErrorAlert } from '../../../utils/DisplayMessage';
import swal from 'sweetalert';

function RemarksBookingStatusUpdate(props) {
    const { remarksModal, ticketdetails, paymentStatus, getExportData } = props;

    const [remarks, setRemarks] = useState("");
    const [dataSpinner, setDataSpinner] = useState(false)
    const [travelAgencyId, setTravelAgencyId] = useState(ticketdetails.travel_agency_id);
    const [busbookingcustomerId, setBusBookingCustomerId] = useState(ticketdetails.bus_booking_customer.id);
    const [busbookingcId, setBusBookingId] = useState(ticketdetails.bus_booking_customer.bus_booking_id);


    const handleChange = (e) => {
        let { name } = e.target;
        let value = e.target.value;
        setRemarks(value);
    };

    const handleSubmit = () => {
        let params = {
            travel_agency_id: travelAgencyId,
            customer_id: busbookingcustomerId,
            bus_booking_id: busbookingcId,
            remarks: remarks,
            payment_status: paymentStatus
        };
        setDataSpinner(true);
        axiosPost(
            URL.PostTicketStatusUpdate,
            params,
            (response) => {
                if (response.status === 200) {
                    // console.log(response.data.data.items)
                    swal("Success", "Remarks Added succesfully", "success");
                    setDataSpinner(false);
                    remarksModal();
                    getExportData();
                }
            },
            (err) => {
                swal("Error", err?.response?.data?.message, "error");
                setDataSpinner(false);
                displayErrorAlert(err);
            }
        );

    }

    return (
        <div className='row'>
            {dataSpinner ? (
                <>
                    <div className='row d-flex align-items-center justify-content-center'>
                        <Spinner></Spinner>
                    </div>
                </>
            ) : (
                <>
                    <div className='col-md-12'>
                        <p> Enter the remarks for status update:</p>
                        <textarea
                            name="feature"
                            className="form-control"
                            value={remarks}
                            onChange={(e) => handleChange(e)}
                        />
                    </div>
                    <div className='col-md-12 mx-2 my-4'>
                        <btn className="btn-success p-2 pe-auto" onClick={handleSubmit}>
                            Submit
                        </btn>
                    </div>
                </>
            )}

        </div>
    )
}

export default RemarksBookingStatusUpdate