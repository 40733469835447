import React, { Component } from "react";
import { Spinner } from "reactstrap";
import { axiosGet, axiosPost } from "../../../utils/AxiosApi";
import { fileUrl, URL } from "../../../utils/Constants";

import CustomModal from "../../../components/WebsiteComponents/CustomModal";

import swal from "sweetalert";
import TicketReceipt from "./TicketReceipt";
import Pagination from "react-js-pagination";
import { displayErrorAlert } from "../../../utils/DisplayMessage";
import CustomFullSpinner from "../../../components/WebsiteComponents/CustomFullSpinner";
import moment from "moment/moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faReceipt, faTimes, faWindowClose } from "@fortawesome/free-solid-svg-icons";

class MyTickets extends Component {
  state = {
    myTickets: [],
    spinner: false,
    receiptModal: false,
    receiptPdf: null,
    media: null,
    current_page: 1,
    totalItemsCount: 0,
    paginated_value: 10,
  };

  componentDidMount() {
    this.getMyTickets();
  }

  toggleModal = () => {
    this.setState({
      receiptModal: !this.state.receiptModal,
    });
  };

  getMyTickets = (pageNumber, paginated_value) => {
    let param = {
      page: pageNumber ? pageNumber : 1,
      paginated_value: paginated_value ? paginated_value : 10,
    }
    this.setState({ spinner: true, myTickets: [] }, function () {
      axiosPost(
        URL.getMyTickets + "/" + "view-tickets", param,
        (response) => {
          if (response.status === 200) {
            this.setState({
              myTickets: response.data.data.items,
              totalItemsCount: response.data.data.totalData,
              current_page: pageNumber,
              spinner: false,
            });
          }
        },
        (err) => {
          this.setState({
            spinner: false,
          });
        }
      );
    });
  };

  handlePaginatedValueChange = (value) => {
    let valuePage = parseInt(value);
    this.setState({ paginated_value: valuePage }, () => {
      this.getMyTickets(1, valuePage);
    });
  };

  handleClickTicket = (param) => {
    if (param.length > 0) {
      // var filePath = fileUrl + "/" + param[0].id + "/" + param[0].file_name;
      this.setState({ receiptPdf: param[0], media: param[0] }, () => {
        this.toggleModal();
      });
    } else {
      swal("Error", "Payment receipt is Unavailable", "error");
    }
  };

  // handleCancelTicket = (data) => {
  //   this.setState(
  //     {
  //       spinner: true,
  //     },
  //     () => {
  //       axiosGet(
  //         URL.cancelTicket + "/" + data.id,
  //         (response) => {
  //           if (response.status === 200) {
  //             swal("Success", "Ticket cancellation successful");
  //             this.getMyTickets();
  //             this.setState({
  //               spinner: false,
  //             });
  //           }
  //         },
  //         (err) => {
  //           displayErrorAlert(err);
  //           this.setState({
  //             spinner: false,
  //           });
  //         }
  //       );
  //     }
  //   );
  // };
  handleCancelTicket = (data) => {
    swal({
      title: "Warning",
      text: "Are you sure to cancel?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.setState(
          {
            spinner: true,
          },
          () => {
            axiosGet(
              URL.cancelTicket + "/" + data.id,
              (response) => {
                if (response.status === 200) {
                  swal("Success", "Ticket cancellation successful\nPlease contact 9851185407 +977-1-5233417 to initiate the refund process", "success");
                  this.getMyTickets();
                  this.setState({
                    spinner: false,
                  });
                }
              },
              (err) => {
                displayErrorAlert(err);
                this.setState({
                  spinner: false,
                });
              }
            );
          }
        );
      }
    });
  };

  render() {
    const { current_page, paginated_value, totalItemsCount } = this.state;

    return (
      <>
        {this.state.loader ? (
          <CustomFullSpinner text="Processing ..."></CustomFullSpinner>
        ) : null}
        <div className="mb-4">
          <div className="my-tickets-title-box text-center mb-5">
            <div className="banner-text">
              <h1>My Tickets</h1>
            </div>
          </div>
          <div className="container">
            <>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>S. No.</th>
                      <th>Bus Info</th>
                      <th>Boarding Points</th>
                      <th>Booked Seats</th>
                      <th>Total Fare</th>
                      <th>Customer Detail</th>
                      <th>Ticket Number</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.myTickets &&
                      this.state.myTickets?.length > 0 ? (
                      this.state.myTickets?.map((ticket, idx) => {
                        return (
                          <tr key={idx}>
                            <td className="text-center">{idx + 1}</td>
                            <td>
                              <strong>{ticket?.bus?.name}</strong>
                              <br></br>
                              <strong>No. Plate: </strong>
                              {ticket?.bus?.number_plate}
                              <br />
                              <strong>Route: </strong>{" "}
                              {ticket?.origin[0]?.country_code} -{" "}
                              {ticket?.destination[0]?.country_code}
                              <br />
                              {ticket?.daily_schedule?.departure_time ?
                                (
                                  <>
                                    <strong>Departure Date: </strong>
                                    {moment(ticket?.daily_schedule.departure_time).format('MM-DD-YYYY')}
                                    <br />
                                  </>) : (<></>)}

                              {ticket.is_manual ? (
                                <>
                                  <span className="badge rounded-pill bg-info text-dark text-white">
                                    Manual Booking
                                  </span>
                                  <br />
                                </>
                              ) : ''}
                            </td>
                            <td>
                              <strong> Pickup point: </strong>
                              <br />
                              {ticket?.pick_up_point?.name != null
                                ? ticket?.pick_up_point?.name
                                : "-"}
                              <br />
                              <strong>Dropping point: </strong>
                              <br />
                              {ticket?.dropping_point?.name != null
                                ? ticket?.dropping_point?.name
                                : "-"}
                            </td>
                            <td>
                              {/* {ticket.bus_seat_booking.map((datum, idx) => {
                                return (
                                  <div key={idx}>
                                    {datum.seatName} ({datum.seat_booked_by})

                                  </div>
                                );
                              })} */}
                              {ticket.bus_seat_booking?.map((datum, idx) => {
                                return (
                                  <div key={idx}>
                                    {datum.seatName}<br />

                                    {(datum.seat_booked_by || datum.remark) &&
                                      (datum.seat_booked_by || datum.remark)}
                                    {/* {ed.bus_seat_booking.length - 1 === idx
                                ? ""
                                : ", "} */}
                                  </div>
                                );
                              })}
                              {ticket?.created_at ?
                                (
                                  <>
                                    <strong>Booking Date: </strong>
                                    {moment(ticket?.created_at).format('MM-DD-YYYY')}
                                    <br />
                                  </>) : (<></>)}
                              {ticket.remark && (
                                <div className="mt-2 border p-2">
                                  <b>Remarks</b>
                                  <p>{ticket.remark}</p>
                                </div>
                              )}
                              {ticket?.payment_status ?
                                (
                                  <>
                                    <strong>
                                      Payment Status: </strong>
                                    {ticket.payment_status}
                                    <br />
                                  </>) : (<></>)}
                            </td>
                            <td>{!ticket.cancellation_status && ticket.payment_status == "Accepted" && (ticket.fare)}</td>
                            <td>
                              <strong>Name: </strong>
                              {ticket.bus_booking_customer?.full_name}
                              <br />
                              <strong>Email: </strong>
                              {ticket.bus_booking_customer?.email}
                              <br />
                              <strong>Contact: </strong>
                              {ticket.bus_booking_customer?.phone_number}
                              <br />
                            </td>
                            <td>
                              {!ticket.cancellation_status && ticket.payment_status == "Accepted" && (ticket.invoice_number)}
                              <br />
                              {(!!ticket.media.length && !ticket.cancellation_status && ticket.payment_status == "Accepted") ?

                                (
                                  <>
                                    <div className="btn btn-primary"
                                      onClick={() =>
                                        this.handleClickTicket(ticket.media)
                                      }
                                    >
                                      <FontAwesomeIcon
                                        className=""
                                        icon={faReceipt}
                                        style={{ cursor: "pointer" }}
                                      />
                                    </div>
                                  </>
                                ) : <></>
                              }
                            </td>
                            <td>

                              {/* <button
                                className="btn btn-danger"
                                onClick={() => this.handleCancelTicket(ticket)}
                                disabled={ticket.cancellation_status}
                              >
                                {ticket.cancellation_status
                                  ? "Canceled"
                                  : "Cancel Ticket"}
                              </button> */}
                              <div className=
                                {!!ticket.cancellation_status || !ticket.update_status ? "btn btn-secondary disabled" : "btn btn-danger "}
                                onClick={() => this.handleCancelTicket(ticket)}
                              >
                                <FontAwesomeIcon
                                  className={ticket.cancellation_status ? "disabled " : ""}
                                  icon={faBan}
                                  style={{ cursor: "pointer" }}
                                  disabled={ticket.cancellation_status}

                                />
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td className="text-center" colSpan={9} style={{ height: "300px" }}>
                          {this.state.spinner ? (
                            <Spinner></Spinner>
                          ) : (
                            "You have not booked any tickets yet"
                          )}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {this.state.totalItemsCount <= 0 ? (
                <></>
              ) : (
                <div className="paginationContainer">
                  <Pagination
                    activePage={current_page}
                    itemsCountPerPage={paginated_value}
                    totalItemsCount={totalItemsCount}
                    onChange={(pageNumber) => this.getMyTickets(pageNumber, paginated_value)}
                    itemClass="page-item"
                    linkClass="page-link"
                    firstPageText="First"
                    lastPageText="Last"
                  />
                  <div className="paginatoion_paginated_value ml-3">
                    <select
                      className="form-control"
                      value={this.state.paginated_value}
                      onChange={(e) => this.handlePaginatedValueChange(e.target.value)}
                    >
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={30}>30</option>
                      <option value={40}>40</option>
                      <option value={50}>50</option>
                    </select>
                  </ div>
                </div>
              )}
            </>
          </div>

          <CustomModal
            modal={this.state.receiptModal}
            modalHeader={"Receipt"}
            modalBody={<TicketReceipt receiptPdf={this.state.receiptPdf} />}
            size="lg"
            toggleModal={this.toggleModal}
          ></CustomModal>
        </div>
      </>
    );
  }
}

export default MyTickets;
