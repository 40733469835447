import React, { useState, useEffect } from "react";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarContent,
  SidebarFooter,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { Link } from "react-router-dom";

import "../../assets/scss/dashboard.scss";
import sidebarBg from "../../assets/images/sidebarBg.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHamburger } from "@fortawesome/free-solid-svg-icons";

function Sidebar(props) {
  const [collapsed, setCollapsed] = useState(false);

  const handleCollapse = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {}, []);

  const chkConditionForCollapse = (e) => {
    let windowWidth = window.innerWidth;

    if (windowWidth < 768) {
      setCollapsed(true);
    }
  };

  return (
    <ProSidebar collapsed={collapsed} image={collapsed ? null : sidebarBg}>
      <SidebarHeader onClick={handleCollapse}>
        {collapsed ? (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ margin: "20px" }}
          >
            <i className="fa fa-bars"></i>
          </div>
        ) : (
          <div className="sidebarHeader">Easy Ticket Bookings</div>
        )}
      </SidebarHeader>
      {!collapsed && (
        <SidebarContent>
          <Menu iconShape="circle">
            {props.menus.length > 0
              ? props.menus
                  .filter((el) => el.display)
                  .map((menu, idx) => {
                    if (menu.subMenus && menu.subMenus.length > 0) {
                      return (
                        <SubMenu
                          key={idx}
                          title={menu.menuName}
                          icon={menu.icon}
                        >
                          {menu.subMenus
                            .filter((el) => el.display)
                            .map((subMenu, sIdx) => {
                              return (
                                <MenuItem
                                  key={sIdx}
                                  icon={subMenu.icon}
                                  // onClick={() => chkConditionForCollapse()}
                                >
                                  {subMenu.menuName}
                                  {subMenu.path ? (
                                    <Link
                                      to={subMenu.path}
                                      onClick={() => chkConditionForCollapse()}
                                    />
                                  ) : null}
                                </MenuItem>
                              );
                            })}
                        </SubMenu>
                      );
                    } else {
                      return (
                        <MenuItem
                          key={idx}
                          icon={menu.icon}
                          onClick={
                            menu.menuName === "Sign Out"
                              ? () => localStorage.clear()
                              : () => chkConditionForCollapse()
                          }
                        >
                          {menu.menuName}
                          {menu.path ? <Link to={menu.path} /> : null}
                        </MenuItem>
                      );
                    }
                  })
              : null}
          </Menu>
        </SidebarContent>
      )}
      <SidebarFooter style={{ textAlign: "center" }}></SidebarFooter>
    </ProSidebar>
  );
}

export default Sidebar;
