import React, { useEffect, useState } from "react";
import { Spinner } from "reactstrap";
import swal from "sweetalert";
import CustomFullSpinner from "../../../components/WebsiteComponents/CustomFullSpinner";
import CustomModal from "../../../components/WebsiteComponents/CustomModal";
import { axiosGet, axiosPost } from "../../../utils/AxiosApi";
import { URL } from "../../../utils/Constants";
import { displayErrorAlert } from "../../../utils/DisplayMessage";
import {
  getRolesFromStorage,
  getTravelAgencyData,
} from "../../../utils/Helper";
import TicketReceipt from "../../Website/MyTickets/TicketReceipt";
import RemarksBookingStatusUpdate from "./RemarksBookingStatusUpdate";
import Pagination from "react-js-pagination";
import moment from "moment/moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faReceipt } from "@fortawesome/free-solid-svg-icons";
import "../../../assets/scss/DashjboardBookingTable.scss"

const DashboardBookingData = () => {
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [travelAgencyId, setTravelAgencyId] = useState("");
  const [operatorId, setOperatorId] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [userRole, setUserRole] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [dataSpinner, setDataSpinner] = useState(false);

  const [travelAgencies, setTravelAgencies] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [operators, setOperators] = useState([]);
  const [exportData, setExportData] = useState([]);

  const [receiptModal, setReceiptModal] = useState(false);
  const [receiptPdf, setReceiptPdf] = useState(null);
  const [media, setMedia] = useState(null);
  const [bookingStatus, setBookingStatus] = useState({});
  const [remarksModal, setRemarksModal] = useState(false);
  const [ticketdetails, setTicketDetails] = useState("");

  const [current_page, setCurrent_page] = useState(1);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [paginated_value, setPaginated_value] = useState(10);

  useEffect(() => {
    let roles = getRolesFromStorage();

    setUserRole(roles);

    if (roles[0] !== "Company Admin") {
      getAllTravelAgency();
    } else {
      let taData = getTravelAgencyData();
      getAllCustomers(taData.id);
      getAllOperators(taData.id);
    }

    getExportData();
  }, []);

  const getAllCustomers = (id) => {
    axiosGet(
      URL.noPaginationAllCustomers + "/" + id,
      (response) => {
        if (response.status === 200) {
          setCustomers(response.data.data.items);
        }
      },
      (err) => {
        displayErrorAlert(err);
      }
    );
  };

  const getAllOperators = (id) => {
    axiosPost(
      URL.travelChildAgency,
      { travel_agency_id: id, pagination: false },
      (response) => {
        if (response.status === 200) {
          setOperators(response.data.data.items);
        }
      },
      (err) => {
        displayErrorAlert(err);
      }
    );
  };

  const getAllTravelAgency = () => {
    axiosGet(
      URL.getAllTravelAgencies,
      (response) => {
        if (response.status === 200) {
          setTravelAgencies(response.data.data.items);
        }
      },
      (err) => {
        displayErrorAlert(err);
      }
    );
  };

  const travelAgencyChange = (e) => {
    setTravelAgencyId(e.target.value);

    setCustomerId("");
    setOperatorId("");
    getAllCustomers(e.target.value);
    getAllOperators(e.target.value);
  };

  const getExportData = (pageNumber, paginated_value) => {
    setCurrent_page(pageNumber)
    let params = {
      page: pageNumber ? pageNumber : 1,
      paginated_value: paginated_value ? paginated_value : 10,

    };

    if (fromDate !== "") {
      params.fromDate = fromDate;
    }

    if (toDate !== "") {
      params.toDate = toDate;
    }

    if (customerId !== "") {
      params.customerId = customerId;
    }

    if (operatorId !== "") {
      params.operatorId = operatorId;
    }

    if (userRole[0] !== "Company Admin") {
      params.travelAgencyId = travelAgencyId;
    }

    setDataSpinner(true);
    setExportData([]);

    axiosPost(
      URL.getExportData,
      params,
      (response) => {
        if (response.status === 200) {
          // console.log(response.data.data.items)
          setExportData(response?.data?.data?.items);
          setTotalItemsCount(response.data.data.totalData)
          setCurrent_page(pageNumber)
          setDataSpinner(false);
        }
      },
      (err) => {
        setDataSpinner(false);
        displayErrorAlert(err);
      }
    );
  };

  const handleExport = () => {
    let expData = [...exportData];

    let expDataArr = [];

    expData.map((ed) => {
      expDataArr.push(ed.id);
    });

    setSpinner(true);

    axiosPost(
      URL.exportBookingData,
      expDataArr,
      (response) => {
        if (response.status === 200) {
          window.open(response.data, "_blank");
          swal("Success", "Data exported successfully");
          setSpinner(false);
        }
      },
      (err) => {
        setSpinner(false);
        displayErrorAlert(err);
      }
    );
  };

  const toggleModal = () => {
    setReceiptModal(!receiptModal);
  };

  const toggleRemarksModal = () => {
    setRemarksModal(!remarksModal);
  }

  const handleClickTicket = (param) => {
    if (param.length > 0) {
      // var filePath = fileUrl + "/" + param[0].id + "/" + param[0].file_name;

      setReceiptPdf(param[0]);
      setMedia(param[0]);
      toggleModal();
    } else {
      swal("Error", "Payment receipt is Unavailable", "error");
    }
  };

  const handleReset = () => {
    setFromDate("");
    setToDate("");
    setTravelAgencyId("");
    setOperatorId("");
    setCustomerId("");
    getExportData();
  };

  useEffect(() => {
    getExportData(current_page);
  }, [travelAgencyId]);


  const handleChange = (e, idx, ed) => {
    const { value } = e.target;
    setTicketDetails(ed);
    setBookingStatus(value);

    // setExportData((prevData) => {
    //   const updatedData = [...prevData];
    //   updatedData[idx].payment_status = value;
    //   return updatedData;
    // });

    toggleRemarksModal()
    // getExportData();
  };

  useEffect(() => {
    // getAllTravelAgency();
    // getExportData();
  }, [exportData])


  const handlePaginatedValueChange = (value) => {
    let valuePage = parseInt(value);
    setPaginated_value(valuePage);
    getExportData(1, valuePage);
  };

  return (
    <div className="h-100">
      {spinner ? (
        <CustomFullSpinner text="Processing ..."></CustomFullSpinner>
      ) : null}
      <div className="dashboardHeader">
        <strong>Booking Data</strong>
      </div>
      <div className="container mt-4 h-100">
        <div className="row">
          <div className="col-md-2">
            <label htmlFor="formDate">From Date</label>
            <input
              className="form-control"
              type="date"
              name="fromDate"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
            />
          </div>
          <div className="col-md-2">
            <label htmlFor="toDate">To Date</label>

            <input
              className="form-control"
              type="date"
              name="toDate"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
              min={fromDate}
            />
          </div>

          {userRole[0] === "Network Admin" && (
            <div className="col-md-2">
              <label htmlFor="travelAgencyId">Travel Agency</label>

              <select
                name="travelAgencyId"
                className="form-select"
                value={travelAgencyId}
                onChange={(e) => travelAgencyChange(e)}
              >
                <option value="" selected disabled>
                  Select travel agency
                </option>
                {travelAgencies.map((ta) => (
                  <option value={ta.id}>{ta.name}</option>
                ))}
              </select>
            </div>
          )}

          <div className="col-md-2">
            <label htmlFor="operatorId">Operator</label>

            <select
              name="operatorId"
              className="form-select"
              value={operatorId}
              onChange={(e) => {
                setOperatorId(e.target.value);

                setCustomerId("");
              }}
              disabled={travelAgencyId === "" ? true : false}
            >
              <option value="" selected disabled>
                Select operator
              </option>
              {operators.map((ta) => (
                <option value={ta.id}>{ta.name}</option>
              ))}
            </select>
          </div>
          <div className="col-md-2">
            <label htmlFor="customerId">Customer</label>
            <select
              name="customerId"
              className="form-select"
              value={customerId}
              onChange={(e) => {
                setCustomerId(e.target.value);

                setOperatorId("");
              }}
              disabled={travelAgencyId === "" ? true : false}
            >
              <option value="" selected disabled>
                Select customer
              </option>
              {customers.map((ta) => (
                <option value={ta.customer_id}>{ta.name}</option>
              ))}
            </select>
          </div>

          <div className="col-md-2">
            <button
              className="btn btn-success mt-4 me-2"
              onClick={() => getExportData(current_page)}
            >
              Search
            </button>

            <button className="btn btn-danger mt-4" onClick={() => handleReset()}>
              Reset
            </button>
          </div>
        </div>

        <div className="mt-4 h-100">
          {exportData?.length > 0 && (
            <div className="text-end">
              <button className="btn btn-primary" onClick={handleExport}>
                Export Data
              </button>
            </div>
          )}
          <div className="col d-flex align-items-center justify-content-between flex-column h-100">
            <div className="table-responsive w-100">
              <table className="table booking-details-table">
                <thead>
                  <tr>
                    <th>S. No.</th>
                    <th>Bus Info</th>

                    <th>Boarding Points</th>
                    <th>Booked Seats</th>
                    <th>Total Fare</th>
                    <th>Customer Detail</th>
                    <th>Ticket Number</th>
                    <th>Payment Status</th>
                    {/* <th>Action</th> */}
                  </tr>
                </thead>
                <tbody>
                  {exportData?.length > 0 ? (
                    exportData?.map((ed, idx) => (
                      <tr key={idx}>
                        <td className="text-center">{idx + 1}</td>
                        <td>
                          <strong>{ed.bus?.name}</strong>
                          <br />
                          <strong>No. Plate: </strong>
                          {ed.bus?.number_plate}
                          <br />
                          <strong>Route: </strong>
                          {ed?.origin[0]?.country_code} -{" "}
                          {ed?.destination[0]?.country_code}
                          <br />
                          {ed?.departure_date ?
                            (
                              <>
                                <strong>Departure Date: </strong>
                                {moment(ed.departure_date).format("MM-DD-YYYY")}
                              </>) : (<></>)}
                          <br />
                          {ed.is_manual ? (
                            <>
                              <span className="badge rounded-pill bg-info text-dark text-white">
                                Manual Booking
                              </span>
                              <br />
                            </>
                          ) : (<></>)}
                          {ed.cancellation_status ? (
                            <>
                              <span className="badge rounded-pill bg-danger text-dark text-white">
                                Cancelled
                              </span>
                              <br />
                            </>
                          ) : (<></>)}
                        </td>
                        <td>
                          <strong> Pickup point: </strong>
                          <br />
                          {ed?.pick_up_point?.name != null
                            ? ed?.pick_up_point?.name
                            : "-"}
                          <br />
                          <strong>Dropping point: </strong>
                          <br />
                          {ed?.dropping_point?.name != null
                            ? ed?.dropping_point?.name
                            : "-"}
                        </td>
                        <td>

                          {ed.bus_seat_booking.map((datum, idx) => {
                            return (
                              <div key={idx}>
                                {datum.seatName} -
                                {(datum.seat_booked_by || datum.remark) &&
                                  (datum.seat_booked_by || datum.remark)}
                              </div>
                            );
                          })}
                          {ed?.created_at ?
                            (
                              <>
                                <strong>Booking Date: </strong>
                                {moment(ed.created_at).format("MM-DD-YYYY")}
                              </>) : (<></>)}
                          {ed.remark && (
                            <div className="mt-2 border p-2">
                              <b>Remarks</b>
                              <p>{ed.remark}</p>
                            </div>
                          )}
                        </td>
                        <td>{ed.fare}</td>
                        <td>
                          <strong>Name: </strong>
                          {ed.bus_booking_customer?.full_name}
                          <br />
                          <strong>Email: </strong>
                          {ed.bus_booking_customer?.email}
                          <br />
                          <strong>Contact: </strong>
                          {ed.bus_booking_customer?.phone_number}
                          <br />
                        </td>
                        <td>
                          <div className="d-flex align-items-center justify-content-center flex-column">
                            {ed?.invoice_number}
                            {
                              ed.payment_status === "Accepted" && ed.media.length !== 0 ?
                                (
                                  <>
                                    {!ed.is_manual ? (
                                      <>
                                        <div className="btn btn-info m-2">
                                          <FontAwesomeIcon
                                            className="text-white"
                                            icon={faReceipt}
                                            onClick={() => handleClickTicket(ed.media)}
                                            style={{ cursor: "pointer" }}
                                          />
                                        </div>
                                      </>
                                    ) : (
                                      <div className="text-muted text-center"></div>
                                    )}
                                  </>)
                                :
                                (null)
                            }

                          </div>
                        </td>
                        <td>
                          <select
                            disabled={ed?.payment_status === "Rejected" || ed?.payment_status === "Refunded" || ed?.update_status}
                            name="status"
                            className="form-select"
                            value={ed?.payment_status}
                            onChange={(e) => handleChange(e, idx, ed)}
                          >
                            <option value="" disabled selected>
                              Choose status
                            </option>
                            <option value="Accepted">Accepted</option>
                            <option value="Pending">Pending </option>
                            <option value="Rejected">Rejected</option>
                            <option value="Refunded">Refunded</option>
                          </select>
                          {/* {ed?.payment_status} */}
                        </td>
                        {/* <td>
                          {
                            ed.payment_status === "Accepted" && ed.media.length !== 0 ?
                              (
                                <>
                                  {!ed.is_manual ? (
                                    <>
                                      <button
                                        className="btn btn-info m-2 text-white"
                                        onClick={() => handleClickTicket(ed.media)}
                                      >
                                        <FontAwesomeIcon
                                        className="mx-5"
                                          icon={faEye}
                                          onClick={() => handleClickTicket(ed.media)}
                                          style={{ cursor: "pointer" }}
                                        />
                                      </button>
                                    </>
                                  ) : (
                                    <div className="text-muted text-center"></div>
                                  )}
                                </>)
                              :
                              (null)
                          }

                        </td> */}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className="text-center" colSpan={9}>
                        {dataSpinner ? (
                          <Spinner></Spinner>
                        ) : (
                          "No tickets available!!   "
                        )}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {totalItemsCount <= 0 ? (
              <></>
            ) : (
              <>
                <div className="paginationContainer">
                  <Pagination
                    activePage={current_page ? current_page : 1}
                    itemsCountPerPage={paginated_value}
                    totalItemsCount={totalItemsCount}
                    onChange={(pageNumber) => getExportData(pageNumber, paginated_value)}
                    itemClass="page-item"
                    linkClass="page-link"
                    firstPageText="First"
                    lastPageText="Last"
                  />
                  <div className="paginatoion_paginated_value ml-3">
                    <select
                      className="form-control"
                      value={paginated_value}
                      onChange={(e) => handlePaginatedValueChange(e.target.value)}
                    >
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={30}>30</option>
                      <option value={40}>40</option>
                      <option value={50}>50</option>
                    </select>
                  </ div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <CustomModal
        modal={receiptModal}
        modalHeader={"Receipt"}
        modalBody={<TicketReceipt receiptPdf={receiptPdf} />}
        size="lg"
        toggleModal={toggleModal}
      ></CustomModal>

      <CustomModal
        modal={remarksModal}
        modalHeader={"Remarks"}
        modalBody={
          <RemarksBookingStatusUpdate
            remarksModal={toggleRemarksModal}
            ticketdetails={ticketdetails}
            paymentStatus={bookingStatus}
            getExportData={getExportData}
          />
        }
        size="lg"
        toggleModal={toggleRemarksModal}
      ></CustomModal>
    </div>
  );
};

export default DashboardBookingData;
