import { faReceipt, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
// import { Pagination } from "reactstrap";
import Pagination from "react-js-pagination";

import { axiosPost } from "../../../utils/AxiosApi";
import { URL } from "../../../utils/Constants";
import TicketContainer from "./TicketContainer";

export class ViewTravelAgencyDetails extends Component {
  state = {
    customerActive: false,
    operatorActive: false,
    detailsList: false,
    ticketsList: false,
    ticketsListData: [],
    activeData: [],
  };

  handleViewTickets = (data) => {
    let param = {};

    if (this.state.customerActive) {
      param = {
        customer_id: data.id,
      };
    } else if (this.state.operatorActive) {
      param = {
        operator_id: data.id,
      };
    }

    axiosPost(URL.viewAllTickets, param, (response) => {
      this.setState({
        ticketsList: true,
        ticketsListData: response.data.data.items,
      });
    });
  };

  handleDetailsClick = (data) => {
    if (data === "customer") {
      this.setState({
        activeData: this.props.customersList,
        customerActive: true,
        operatorActive: false,
      });
    } else {
      this.setState({
        activeData: this.props.operatorList,
        operatorActive: true,
        customerActive: false,
      });
    }
    this.setState({
      detailsList: true,
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.customersList !== this.props.customersList) {
      this.setState({
        activeData: this.props.customersList,
      });
    }

    if (prevProps.operatorList !== this.props.operatorList) {
      this.setState({
        activeData: this.props.operatorList,
      });
    }
  }

  goBack = () => {
    if (this.state.detailsList && !this.state.ticketsList) {
      this.setState({
        detailsList: !this.state.detailsList,
      });
    } else if (this.state.detailsList && this.state.ticketsList) {
      this.setState({
        ticketsList: !this.state.ticketsList,
      });
    }
  };

  render() {
    const { current_page, per_page, total } = this.state.activeData;

    return (
      <div className="container">
        {this.state.detailsList && this.state.ticketsList ? (
          <div className="view-tickets-list">
            <button className="btn btn-info mb-2" onClick={this.goBack}>
              Go back
            </button>
            <TicketContainer ticketsListData={this.state.ticketsListData} />
          </div>
        ) : this.state.detailsList ? (
          <div className="details-list">
            <button className="btn btn-info mb-2" onClick={this.goBack}>
              Go back
            </button>

            <div className="table-responsive">
              <table className="table table-bordered table-striped mb-0 text-center">
                <thead>
                  <tr>
                    <th>S.N</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Contact No.</th>
                    <th>Address</th>
                    <th>Options</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.loader ? (
                    <tr>
                      <td colSpan={6}>Spinner</td>
                    </tr>
                  ) : (
                    this.state.activeData?.items?.map((cl, idx) => (
                      <tr>
                        <td>{idx + 1}</td>

                        <td>
                          {cl.name
                            ? cl.name
                            : cl.first_name + " " + cl.last_name}
                        </td>
                        <td>{cl.email}</td>
                        <td>
                          {cl.phone_number
                            ? cl.phone_number
                            : cl.contact_details}
                        </td>
                        <td>{cl.address}</td>

                        <td>
                          <button
                            className="btn btn-info"
                            onClick={() => this.handleViewTickets(cl)}
                          >
                            View Tickets
                          </button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>

            <div className="paginationContainer">
              <Pagination
                activePage={current_page}
                itemsCountPerPage={per_page}
                totalItemsCount={total}
                onChange={(pageNumber) =>
                  this.state.customerActive
                    ? this.props.getAllCustomers(null, pageNumber)
                    : this.props.getAllOperators(null, pageNumber)
                }
                itemClass="page-item"
                linkClass="page-link"
                firstPageText="First"
                lastPageText="Last"
              />
            </div>
          </div>
        ) : (
          <div className="view-detail-container">
            <div className="view-details-box">
              <div
                className="dashboard-box"
                onClick={() => this.handleDetailsClick("customer")}
              >
                <div className="dashboard-box-head">
                  <h3>No. of Customers</h3>
                  <FontAwesomeIcon icon={faUsers} size="2x" />
                </div>
                <p>{this.props.customersList.total}</p>
              </div>
            </div>

            <div className="view-details-box">
              <div
                className="dashboard-box"
                onClick={() => this.handleDetailsClick("operator")}
              >
                <div className="dashboard-box-head">
                  <h3>No. of Operators</h3>
                  <FontAwesomeIcon icon={faReceipt} size="2x" />
                </div>
                <p>{this.props.operatorList.total}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default ViewTravelAgencyDetails;
