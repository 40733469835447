import React, { Component } from "react";
import swal from "sweetalert";

import { fileUrl, URL } from "../../../../utils/Constants";
import {
  displayErrorAlert,
  DISPLAYMESSAGE,
} from "../../../../utils/DisplayMessage";
import { axiosGet, axiosPost } from "../../../../utils/AxiosApi";
import CustomFullSpinner from "../../../../components/WebsiteComponents/CustomFullSpinner";
import "../../../../assets/scss/dashboard.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { getRolesFromStorage } from "../../../../utils/Helper";

class CreateBus extends Component {
  state = {
    name: "",
    numberPlate: "",
    busTypeId: "",
    feature: "",
    images: [],
    loader: false,
    busTypes: [],
    id: "",
    roles: [],
    travelAgencies: [],
    travelAgencyId: "",
  };

  getAllTravelAgency = () => {
    axiosGet(
      URL.getAllTravelAgencies,
      (response) => {
        if (response.status === 200) {
          this.setState({
            travelAgencies: response.data.data.items,
          });
        }
      },
      (err) => {
        displayErrorAlert(err);
      }
    );
  };

  componentDidMount() {
    if (this.props.edit) {
      this.editConfig();
    }
    this.getBusTypes();
    let roles = getRolesFromStorage();

    if (roles[0] === "Network Admin") {
      this.getAllTravelAgency();
    }

    this.setState({
      roles: roles,
    });
  }

  editConfig = () => {
    let selectedData = this.props.selectedData;
    let media = selectedData.media,
      images = [];
    if (media.length > 0) {
      media.forEach((el) => {
        images.push({
          src: fileUrl + "/" + el.id + "/" + el.file_name,
          image: el,
          edit: true,
        });
      });
    }
    this.setState({
      numberPlate: selectedData.number_plate,
      busTypeId: selectedData.bus_type_id,
      feature: selectedData.feature,
      images,
      id: selectedData.id,
      name: selectedData.name,
      travelAgencyId: selectedData.travel_agency_id,
    });
  };

  getBusTypes = () => {
    let param = {

    }
    axiosPost(
      URL.busTypes + "/get-bus-type", param,
      (response) => {
        if (response.status === 200) {
          this.setState({
            busTypes: response.data.data.items,
          });
        }
      },
      (err) => {
        displayErrorAlert(err);
      }
    );
  };

  handleCancel = () => {
    this.setState({
      name: "",
      numberPlate: "",
      busTypeId: "",
      feature: "",
      images: [],
    });

    this.props.handleCreateModal();
  };

  handleChange = (e) => {
    let { name } = e.target;
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    this.setState({ [name]: value });

    if (name === "busTypeId") {
      const featureData = this.state.busTypes.find(
        (bt) => bt.id === parseInt(value)
      );

      this.setState({
        [name]: value,
        feature: featureData.feature,
      });
    }
  };

  handleImageChange = (e) => {
    e.preventDefault();
    let data = e.target.files;
    let images = [...this.state.images];
    for (let i = 0; i < data.length; i++) {
      images.push({
        src: window.URL.createObjectURL(data[i]),
        image: data[i],
      });
    }
    this.setState({ images });
  };

  handleSave = () => {
    let bus = {};
    if (this.state.roles[0] === "Network Admin") {
      bus = {
        name: this.state.name,
        numberPlate: this.state.numberPlate,
        busTypeId: this.state.busTypeId,
        feature: this.state.feature,
        travelAgencyId: this.state.travelAgencyId,
      };
    } else {
      bus = {
        name: this.state.name,
        numberPlate: this.state.numberPlate,
        busTypeId: this.state.busTypeId,
        feature: this.state.feature,
      };
    }

    let formData = new FormData();
    formData.append("bus", JSON.stringify(bus));
    if (this.state.images.length > 0) {
      let newFiles = [];
      this.state.images.forEach((el) => {
        newFiles.push(el.image);
      });
      newFiles.forEach((image) => formData.append("file[]", image));
    }

    if (
      this.state.busTypeId === "" ||
      this.state.feature === "" ||
      this.state.images.length < 1 ||
      this.state.name === "" ||
      (this.state.roles[0] === "Network Admin" &&
        this.state.travelAgencyId === "")
    ) {
      swal("Error", "Fill all the fields", "error");
      return;
    } else {
      this.setState({ loader: true }, () => {
        axiosPost(
          URL.buses,
          formData,
          (response) => {
            if (response.status === 200) {
              swal("Success", DISPLAYMESSAGE.createSuccess, "success");
              this.setState({ loader: false });
              this.props.getBuses();
              this.props.handleCreateModal();
            }
          },
          (err) => {
            displayErrorAlert(err);
            this.setState({ loader: false });
          }
        );
      });
    }
  };

  handleUpdate = () => {
    let bus = {};
    if (this.state.roles[0] === "Network Admin") {
      bus = {
        name: this.state.name,
        numberPlate: this.state.numberPlate,
        busTypeId: this.state.busTypeId,
        feature: this.state.feature,
        travelAgencyId: this.state.travelAgencyId,
        id: this.state.id,
      };
    } else {
      bus = {
        name: this.state.name,
        numberPlate: this.state.numberPlate,
        busTypeId: this.state.busTypeId,
        feature: this.state.feature,
        id: this.state.id,
      };
    }

    let formData = new FormData();
    let oldImages = [];
    formData.append("bus", JSON.stringify(bus));
    if (this.state.images.length > 0) {
      let newFiles = [];
      this.state.images.forEach((el) => {
        if (!el.edit) {
          newFiles.push(el.image);
        } else {
          oldImages.push({
            id: el.image.id,
            model_type: el.image.model_type,
            model_id: el.image.model_id,
            file_name: el.image.file_name,
            uuid: el.image.uuid,
          });
        }
      });
      newFiles.forEach((image) => formData.append("file[]", image));
    }
    formData.append("oldImages", JSON.stringify(oldImages));

    if (
      this.state.busTypeId === "" ||
      this.state.feature === "" ||
      this.state.images.length < 1 ||
      this.state.name === "" ||
      (this.state.roles[0] === "Network Admin" &&
        this.state.travelAgencyId === "")
    ) {
      swal("Error", "Fill all the fields", "error");
      return;
    } else {
      this.setState({ loader: true }, () => {
        axiosPost(
          URL.updateBus,
          formData,
          (response) => {
            if (response.status === 200) {
              this.setState({ loader: false });

              swal("Success", DISPLAYMESSAGE.updateSuccess, "success");
              this.props.getBuses();
              this.props.handleCreateModal();
            }
          },
          (err) => {
            displayErrorAlert(err);
            this.setState({ loader: false });
          }
        );
      });
    }
  };

  removeImage = (idx) => {
    let images = [...this.state.images];
    images.splice(idx, 1);
    this.setState({ images });
  };

  travelAgencyChange = (e) => {
    this.setState({
      travelAgencyId: e.target.value,
    });
  };

  render() {
    return (
      <div className="container">
        {this.state.loader ? (
          <CustomFullSpinner text="Processing ..."></CustomFullSpinner>
        ) : null}
        <div className="routeMainHolder">
          <div className="container-fluid">
            <div className="row createRouteHolder">
              <div className="col-md-11">
                <div className="row">
                  {this.state.roles[0] === "Network Admin" && (
                    <div className="col-md-12 mb-3">
                      <strong>Travel Agency
                        <span className="text-danger"> *</span>
                      </strong>

                      <select
                        name="travelAgencyId"
                        className="form-select"
                        value={this.state.travelAgencyId}
                        onChange={(e) => this.travelAgencyChange(e)}
                      >
                        <option value="" selected disabled>
                          Select travel agency
                        </option>
                        {this.state.travelAgencies.map((ta) => (
                          <option value={ta.id}>{ta.name}</option>
                        ))}
                      </select>
                    </div>
                  )}
                  <div className="col-md-2 mt-1">
                    <strong>Bus name
                      <span className="text-danger"> *</span>
                    </strong>
                  </div>
                  <div className="col-md-2">
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value={this.state.name}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="col-md-2 mt-1 text-center">
                    <strong>Number Plate
                    </strong>
                  </div>
                  <div className="col-md-2">
                    <input
                      type="text"
                      className="form-control"
                      name="numberPlate"
                      value={this.state.numberPlate}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="col-md-1 mt-1 pe-0">
                    <strong>Bus Type
                      <span className="text-danger"> *</span>
                    </strong>
                  </div>
                  <div className="col-md-3">
                    <select
                      name="busTypeId"
                      className="form-select"
                      onChange={this.handleChange}
                      value={this.state.busTypeId}
                    >
                      <option value="" disabled selected>
                        Select Bus Type
                      </option>
                      {this.state.busTypes?.map((item, idx) => {
                        return (
                          <option key={idx} value={item.id}>
                            {item.type}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-2 mt-1">
                    <strong>Feature
                      <span className="text-danger"> *</span>
                    </strong>
                  </div>
                  <div className="col-md-10">
                    <textarea
                      name="feature"
                      className="form-control"
                      value={this.state.feature}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-2 mt-1">
                    <strong>Bus Images
                      <span className="text-danger"> *</span>
                    </strong>
                  </div>
                  <div className="col-md-10">
                    <div>
                      <input
                        id="image"
                        type="file"
                        accept="image/png, image/jpeg"
                        ref={(ref) => (this.upload = ref)}
                        style={{ display: "none" }}
                        onChange={(e) => this.handleImageChange(e)}
                        multiple
                        required
                      />
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          this.upload.click();
                        }}
                      >
                        Add Images
                      </button>
                      {this.state.images?.length > 0 ? (
                        <div className="busImageHolder">
                          {this.state.images?.map((image, idx) => {
                            return (
                              <div key={idx}>
                                <img src={image.src} />
                                <FontAwesomeIcon
                                  icon={faTimesCircle}
                                  style={{
                                    position: "absolute",
                                    right: "5px",
                                    top: "5px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => this.removeImage(idx)}
                                />
                              </div>
                            );
                          })}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col text-end">
            {this.props.edit ? (
              <button className="btn btn-primary" onClick={this.handleUpdate}>
                Update
              </button>
            ) : (
              <button className="btn btn-primary" onClick={this.handleSave}>
                Create
              </button>
            )}

            <button
              className="btn btn-danger"
              style={{ marginLeft: "10px" }}
              onClick={this.handleCancel}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateBus;
