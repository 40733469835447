import React, { Component } from "react";
import Select from "react-select";
import CustomFullSpinner from "../../../../components/WebsiteComponents/CustomFullSpinner";
import { axiosGet, axiosPost } from "../../../../utils/AxiosApi";
import { URL } from "../../../../utils/Constants";

import makeAnimated from "react-select/animated";
import swal from "sweetalert";
import {
  displayErrorAlert,
  DISPLAYMESSAGE,
} from "../../../../utils/DisplayMessage";
import { getRolesFromStorage } from "../../../../utils/Helper";
import moment from "moment";

const animatedComponents = makeAnimated();

class CreateBusSchedule extends Component {
  state = {
    busId: this.props.updateReqData ? this.props.updateReqData.bus_id : "",
    busRoutes: [],
    busRouteId: this.props.updateReqData
      ? this.props.updateReqData.route_id
      : "",

    configBusRoutes: this.props.updateReqData
      ? [this.props.updateReqData.route]
      : [],
    isLoading: false,
    loader: false,
    selectedBusRoutes: [],
    scheduleSetting: "auto",
    weekDays: [
      { name: "Sunday", status: false },
      { name: "Monday", status: false },
      { name: "Tuesday", status: false },
      { name: "Wednesday", status: false },
      { name: "Thursday", status: false },
      { name: "Friday", status: false },
      { name: "Saturday", status: false },
    ],
    startDate: "",
    endDate: "",
    todayDate: moment(new Date()).format("YYYY-MM-DD"),
  };

  checkDeletedRoutesData = () => {
    let configBusRoutes = [...this.state.configBusRoutes];
    let selectedBusRoutes = [...this.state.selectedBusRoutes],
      deletedIds = [];
    if (configBusRoutes.length > 0) {
      configBusRoutes.forEach((el) => {
        const index = selectedBusRoutes.findIndex(
          (element) => element.value === el.id
        );
        if (index === -1) {
          deletedIds.push(el.id);
        }
      });
    }
    if (deletedIds.length > 0) {
      deletedIds.forEach((el) => {
        configBusRoutes = configBusRoutes.filter((f) => f.id !== el);
      });
    }
    this.setState({ configBusRoutes });
  };

  getBusRoutes = () => {
    this.setState({ busRoutes: [], isLoading: true }, function () {
      axiosGet(
        URL.getBusRoute + "/" + this.state.busId,
        (response) => {
          if (response.status === 200) {
            let datum = response.data.data.items[0].routes;
            let options = [];
            if (datum.length > 0) {
              datum.forEach((el) => {
                options.push({
                  label: `${el.origin_city.name} - ${el.destination_city.name}`,
                  value: el.id,
                  data: el,
                });
              });
            }
            this.setState({ busRoutes: options, isLoading: false });
          }
        },
        (err) => {
          this.setState({ isLoading: false });
        }
      );
    });
  };

  handleBusRouteChange = (item) => {
    this.setState({ selectedBusRoutes: item }, function () {
      let selectedRoutes = [...this.state.selectedBusRoutes],
        configBusRoutes = [...this.state.configBusRoutes];
      if (selectedRoutes.length > 0) {
        selectedRoutes.forEach((el) => {
          if (configBusRoutes.length > 0 && configBusRoutes[0] !== null) {
            const index = configBusRoutes.findIndex((element) => {
              if (element.id === el.value) {
                return true;
              }
            });
            if (index === -1) {
              el.data.weekDays = JSON.parse(
                JSON.stringify(this.state.weekDays)
              );
              el.data.scheduleSetting = this.state.scheduleSetting;
              el.data.startDate = this.state.startDate;
              el.data.endDate = this.state.endDate;

              configBusRoutes.push(el.data);
            }
          } else {
            el.data.weekDays = JSON.parse(JSON.stringify(this.state.weekDays));
            el.data.scheduleSetting = this.state.scheduleSetting;
            el.data.startDate = this.state.startDate;
            el.data.endDate = this.state.endDate;

            configBusRoutes.push(el.data);
          }
        });
      } else {
        configBusRoutes = [];
      }
      this.setState({ configBusRoutes }, function () {
        this.checkDeletedRoutesData();
      });
    });
  };

  handleCancel = () => {
    this.setState({
      busId: "",
      busRoutes: [],
      busRouteId: "",
      configBusRoutes: [],
      isLoading: false,
      loader: false,
      selectedBusRoutes: [],
    });
    this.props.handleCreateModal();
  };

  handleChange = (e) => {
    let { name, value } = e.target;
    this.setState({ [name]: value }, function () {
      if (name === "busId") {
        this.setState(
          {
            selectedBusRoutes: [],
            configBusRoutes: [],
          },
          () => {
            this.getBusRoutes();
          }
        );
      }
    });
  };

  handleChangeRouteDetails = (e, idx) => {
    let { name, value } = e.target;

    if (name === "startDate") {
      this.setState((prevState) => {
        const newConfigBusRoutes = [...prevState.configBusRoutes];
        if (
          this.state.configBusRoutes[idx].startDate >
          this.state.configBusRoutes[idx].endDate
        ) {
          this.state.configBusRoutes[idx].endDate = "";
        }
        newConfigBusRoutes[idx][name] = value;
        return { configBusRoutes: newConfigBusRoutes };
      });
    } else {
      this.setState((prevState) => {
        const newConfigBusRoutes = [...prevState.configBusRoutes];
        newConfigBusRoutes[idx][name] = value;
        return { configBusRoutes: newConfigBusRoutes };
      });
    }
  };

  // handleChangeRouteDetails = (e, idx) => {
  //   let { name, value } = e.target;

  //   if (
  //     name === "startDate" &&
  //     this.state.configBusRoutes[idx].startDate <
  //       this.state.configBusRoutes[idx].endDate
  //   ) {
  //     this.state.configBusRoutes[idx].endDate = "";
  //   } else {
  //     this.state.configBusRoutes[idx][name] = value;
  //   }
  // };

  handleSave = () => {
    let schedules = [];
    let errCount = 0;
    if (this.state.configBusRoutes.length > 0 && this.state.busId !== "") {
      this.state.configBusRoutes.forEach((el) => {
        if (el.startDate !== "" && el.endDate !== "") {
          let days = [];
          el.weekDays.forEach((wD) => {
            if (wD.status) {
              days.push(wD.name);
            }
          });
          schedules.push({
            route_id: el.id,
            days: days,
            auto_schedule: el.scheduleSetting === "auto" ? true : false,
            start_date: el.startDate,
            end_date: el.endDate,
          });
        } else {
          errCount++;
          return;
        }
      });
      if (errCount > 0) {
        swal("Error", "Start date and end date are mandatory", "error");
      } else {
        let param = {
          bus_id: this.state.busId,
          schedules: schedules,
        };

        this.setState({ loader: true }, () => {
          axiosPost(
            URL.busScheduling,
            param,
            (response) => {
              if (response.status === 200) {
                swal("Success", DISPLAYMESSAGE.insertSuccess, "success");
                this.props.getBusSchedules();
                this.props.handleCreateModal();
              }
            },
            (err) => {
              displayErrorAlert(err);
              this.setState({ loader: false });
            }
          );
        });
      }
    } else {
      swal("Error", "No Bus or Routes selected for insertion", "error");
    }
  };

  handleWeekdayChange = (e, routeIdx, weekIdx) => {
    let configBusRoutes = [...this.state.configBusRoutes],
      { checked } = e.target;
    configBusRoutes[routeIdx].weekDays[weekIdx].status = checked;
    this.setState({ configBusRoutes });
  };

  componentDidMount = () => {
    // if (this.props.updateReqData.length > 0) {
    //   this.getBusRoutes();

    // }
    if (this.props.updateReqData !== "") {
      this.updateConfig();
    }
  };

  updateConfig = () => {
    this.setState(
      {
        configBusRoutes: [this.props.updateReqData.route],
      },
      function () {
        let configBusRoutes = [...this.state.configBusRoutes];
        if (configBusRoutes.length > 0 && configBusRoutes[0] !== null) {
          configBusRoutes.forEach((el) => {
            el.startDate = this.props.updateReqData.start_date;
            el.endDate = this.props.updateReqData.end_date;
            el.scheduleSetting =
              this.props.updateReqData.auto_schedule == 1 ? "auto" : "manual";
            el.weekDays =
              this.state.weekDays.length > 0
                ? JSON.parse(JSON.stringify(this.state.weekDays))
                : [];
            el.weekDays.forEach((wD) => {
              let days = this.props.updateReqData.days;
              let check = days.findIndex((d) => d.day === wD.name);
              if (check !== -1) {
                wD.status = true;
              }
            });
          });

          this.setState({ configBusRoutes });
        }
      }
    );
  };

  // handleUpdate = () => {
  //   let schedules = [];
  //   let scheduleSetting = "";
  //   let startDate = "";
  //   let endDate = "";
  //   this.state.configBusRoutes.forEach((el) => {
  //     scheduleSetting = el.scheduleSetting;
  //     startDate = el.startDate;
  //     endDate = el.endDate;
  //     let days = [];
  //     el.weekDays.forEach((wD) => {
  //       if (wD.status) {
  //         days.push(wD.name);
  //       }
  //     });
  //     schedules.push({
  //       days: days,
  //     });
  //   });
  //   let param = {
  //     schedule_setting_id: this.props.updateReqData.id,
  //     days: schedules[0].days,
  //     auto_schedule: scheduleSetting === "auto" ? true : false,
  //     start_date: startDate,
  //     end_date: endDate,
  //   };

  //   this.setState({ loader: true }, () => {
  //     axiosPost(
  //       URL.updateBusScheduleSetting,
  //       param,
  //       (response) => {
  //         if (response.status === 200) {
  //           swal("Success", DISPLAYMESSAGE.updateSuccess, "success");
  //           this.props.getBusSchedules();
  //           this.props.handleCreateModal();
  //         }
  //       },
  //       (err) => {
  //         displayErrorAlert(err);
  //         this.setState({ loader: false });
  //       }
  //     );
  //   });
  // };
  handleUpdate = () => {
    const schedules = [];
    let scheduleSetting = "";
    let startDate = "";
    let endDate = "";

    this.state.configBusRoutes.forEach((el) => {
      scheduleSetting = el.scheduleSetting;
      startDate = el.startDate;
      endDate = el.endDate;
      const days = el.weekDays.filter((wD) => wD.status).map((wD) => wD.name);
      schedules.push({ days });
    });

    const param = {
      schedule_setting_id: this.props.updateReqData.id,
      days: schedules[0].days,
      auto_schedule: scheduleSetting === "auto" ? true : false,
      start_date: startDate,
      end_date: endDate,
    };

    if (startDate !== "" && endDate !== "") {
      this.setState({ loader: true }, () => {
        axiosPost(
          URL.updateBusScheduleSetting,
          param,
          (response) => {
            if (response.status === 200) {
              swal("Success", DISPLAYMESSAGE.updateSuccess, "success");
              this.props.getBusSchedules();
              this.props.handleCreateModal();
            }
          },
          (err) => {
            displayErrorAlert(err);
            this.setState({ loader: false });
          }
        );
      });
    } else {
      swal("Error", "Start date and end date are mandatory", "error");
    }
  };

  render() {
    const busesData = this.props.buses;

    const groupedBuses = busesData.reduce((acc, bus) => {
      const key = bus.travel_agency_id;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(bus);
      return acc;
    }, {});

    const roles = getRolesFromStorage();

    return (
      <div className="container-fluid">
        {this.state.loader ? (
          <CustomFullSpinner text="Processing ..."></CustomFullSpinner>
        ) : null}
        {this.props.updateReqData ? null : (
          <div className="row">
            <div className="col-md-1" style={{ marginTop: "6px" }}>
              <strong>Select Bus<span className="text-danger">*</span>
              </strong>
            </div>
            <div className="col-md-3">
              <select
                name="busId"
                value={this.state.busId}
                className="form-select"
                onChange={this.handleChange}
              >
                <option value="" disabled selected>
                  Choose Bus
                </option>
                {roles[0] === "Network Admin"
                  ? Object.entries(groupedBuses).map(([key, buses]) => (
                    <optgroup label={buses[0].travel_agency.name}>
                      {buses.map((bus, idx) => (
                        <option value={bus.id} key={idx}>
                          {bus.name} ({bus.number_plate})
                        </option>
                      ))}
                    </optgroup>
                  ))
                  : this.props.buses.map((bus, idx) => (
                    <option value={bus.id} key={idx}>
                      {bus.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="col-md-2 text-end" style={{ marginTop: "6px" }}>
              <strong>Select Routes
                <span className="text-danger">*</span>
              </strong>
            </div>
            <div className="col">
              <Select
                isMulti
                isClearable={false}
                closeMenuOnSelect={false}
                components={animatedComponents}
                options={this.state.busRoutes}
                name="selectedRoutes"
                value={this.state.selectedBusRoutes}
                onChange={(item) => this.handleBusRouteChange(item)}
                placeholder="Select Routes for Scheduling"
                isLoading={this.state.isLoading}
                isDisabled={this.state.busId === ""}
                className="scheduler"
              ></Select>
            </div>
          </div>
        )}
        {this.state.configBusRoutes.length > 0 ? (
          <div className="row mt-2">
            <div className="col">
              <div className="routeScheduleHolder">
                {this.state.configBusRoutes.map((route, idx) => {
                  return (
                    <div
                      key={idx}
                      className={
                        idx === this.state.configBusRoutes.length - 1
                          ? ""
                          : "mb-3"
                      }
                    >
                      <div className="routeScheduleHeader d-flex justify-content-between flex-column">
                        <div className="d-flex align-items-center justify-content-between">
                          <h5>
                            {route?.origin_city.name} -{" "}
                            {route?.destination_city.name}
                          </h5>
                          <div className="d-flex align-items-center ">
                            <label className="me-3"> Schedule:</label>
                            <div className="form-group">
                              <select
                                className="form-control"
                                onChange={(e) =>
                                  this.handleChangeRouteDetails(e, idx)
                                }
                                name="scheduleSetting"
                                value={route.scheduleSetting}
                              >
                                <option value="manual">Manual</option>
                                <option value="auto">Automatic</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <label className="">
                          Schedule duration{" "}
                          {/* <small className="text-warning">
                            {" "}
                            (*Note: The schedule will be run for one weeks if no
                            any dates are selected){" "}
                          </small> */}
                        </label>{" "}
                        <div className="d-flex align-items-center">
                          <div className="form-group me-3">
                            <label>Start Date
                              <span className="text-danger"> *</span>
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              onChange={(e) =>
                                this.handleChangeRouteDetails(e, idx)
                              }
                              name="startDate"
                              min={this.state.todayDate}
                              value={route.startDate}
                            />
                          </div>
                          <div className="form-group">
                            <label>End Date
                              <span className="text-danger"> *</span>
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              onChange={(e) =>
                                this.handleChangeRouteDetails(e, idx)
                              }
                              name="endDate"
                              value={route.endDate}
                              min={route.startDate}
                              disabled={!route.startDate}
                            />
                          </div>
                        </div>
                      </div>
                      <table className="table table-striped table-bordered mb-0 text-center">
                        <thead>
                          <tr>
                            {route?.weekDays &&
                              route?.weekDays.map((wDay, dIdx) => (
                                <th key={dIdx}>{wDay.name}</th>
                              ))}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            {route?.weekDays &&
                              route?.weekDays.map((wDay, wIdx) => (
                                <td key={wIdx}>
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    name="is_open_for_booking"
                                    checked={wDay.status}
                                    onChange={(e) =>
                                      this.handleWeekdayChange(e, idx, wIdx)
                                    }
                                  ></input>
                                </td>
                              ))}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        ) : null}
        <div className="row mt-3">
          <div className="col text-end">
            {this.props.editMode ? (
              <button
                className="btn btn-primary me-3"
                onClick={this.handleUpdate}
              >
                Update
              </button>
            ) : (
              <button
                className="btn btn-primary me-3"
                onClick={this.handleSave}
              >
                Save
              </button>
            )}
            <button className="btn btn-danger" onClick={this.handleCancel}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateBusSchedule;
