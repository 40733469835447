import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import swal from "sweetalert";
import CustomFullSpinner from "../../../../components/WebsiteComponents/CustomFullSpinner";
import { axiosPost } from "../../../../utils/AxiosApi";
import { URL } from "../../../../utils/Constants";
import {
  displayErrorAlert,
  DISPLAYMESSAGE,
} from "../../../../utils/DisplayMessage";

class CreateFAQ extends Component {
  state = {
    faqs: [{ questions: "", answer: "" }],
    spinner: false,
  };
  addRow = () => {
    this.setState({
      faqs: [...this.state.faqs, { questions: "", answer: "" }],
    });
  };

  componentDidMount() {
    if (this.props.edit) {
      this.setUpdateConfig();
    }
  }

  deleteRow = (idx) => {
    let faqs = [...this.state.faqs];
    faqs.splice(idx, 1);
    this.setState({ faqs });
  };

  handleCancel = () => {
    this.setState({ faqs: [{ questions: "", answer: "" }] });
  };

  handleChange = (e, idx) => {
    let { name, value } = e.target;
    let faqs = [...this.state.faqs];
    faqs[idx][name] = value;
    this.setState({ faqs });
  };

  handleSave = () => {
    if (this.state.faqs.some((f) => f.questions === "" || f.answer === "")) {
      swal("Error", "Questions and answers cannot be empty");
    } else {
      this.setState({ spinner: true }, function () {
        axiosPost(
          URL.insertFAQ,
          this.state.faqs,
          (response) => {
            this.setState({ spinner: false });
            if (response.status === 200) {
              swal("Success", DISPLAYMESSAGE.createSuccess, "success");
              this.props.getFAQs();
              this.props.handleCreateModal();
            }
          },
          (err) => {
            this.setState({ spinner: false });
            displayErrorAlert(err);
          }
        );
      });
    }
  };

  handleUpdate = () => {
    let data = {
      id: this.props.selectedData.id,
      questions: this.state.faqs[0].questions,
      answer: this.state.faqs[0].answer,
    };
    if (this.state.faqs.some((f) => f.questions === "" || f.answer === "")) {
      swal("Error", "Questions and answers cannot be empty");
    } else {
      this.setState({ spinner: true }, function () {
        axiosPost(
          URL.updateFAQs,
          data,
          (response) => {
            this.setState({ spinner: false });
            if (response.status === 200) {
              swal("Success", DISPLAYMESSAGE.updateSuccess, "success");
              this.props.getFAQs();
              this.props.handleCreateModal();
            }
          },
          (err) => {
            this.setState({ spinner: false });
            displayErrorAlert(err);
          }
        );
      });
    }
  };

  setUpdateConfig = () => {
    let faq = this.props.selectedData;
    if (faq) {
      let faqs = [];
      let tempData = {
        questions: faq.questions,
        answer: faq.answer,
      };
      faqs.push(tempData);
      this.setState({ faqs });
    }
  };

  render() {
    return (
      <div className="container">
        {this.state.spinner ? (
          <CustomFullSpinner text="Processing ..."></CustomFullSpinner>
        ) : null}
        <div className="routeMainHolder">
          <div className="container-fluid">
            {this.state.faqs.map((faq, idx) => {
              return (
                <div className="row createRouteHolder" key={idx}>
                  <div className="col-md-11">
                    <div className="row">
                      <div className="col-md-3 mt-1">
                        <strong>Question:
                          <span className="text-danger"> *</span></strong>
                      </div>
                      <div className="col-md-9">
                        <input
                          type="text"
                          className="form-control"
                          name="questions"
                          value={faq.questions}
                          onChange={(e) => this.handleChange(e, idx)}
                        />
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-3 mt-1">
                        <strong>Answer:
                          <span className="text-danger"> *</span>
                        </strong>
                      </div>
                      <div className="col-md-9">
                        <textarea
                          className="form-control"
                          name="answer"
                          value={faq.answer}
                          onChange={(e) => this.handleChange(e, idx)}
                        />
                      </div>
                    </div>
                  </div>
                  {this.props.edit ? null : (
                    <div className="col-md-1 pe-0">
                      {idx === this.state.faqs.length - 1 ? (
                        <FontAwesomeIcon
                          icon={faPlusCircle}
                          onClick={this.addRow}
                          style={{ marginRight: "5px", color: "#0b5ed7" }}
                        />
                      ) : null}
                      {idx == 0 ? null : (
                        <FontAwesomeIcon
                          icon={faMinusCircle}
                          style={{ color: "#bb2d3b" }}
                          onClick={() => this.deleteRow(idx)}
                        />
                      )}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <div className="row mt-3">
          <div className="col text-end">
            {this.props.edit ? (
              <button className="btn btn-primary" onClick={this.handleUpdate}>
                Update
              </button>
            ) : (
              <button className="btn btn-primary" onClick={this.handleSave}>
                Create
              </button>
            )}
            {/* <button
              className="btn btn-danger"
              style={{ marginLeft: "10px" }}
              onClick={this.handleCancel}
            >
              Cancel
            </button> */}
          </div>
        </div>
      </div>
    );
  }
}

export default CreateFAQ;
