//Define URL and constants here
export const headers = {
  Authorization: `Bearer ${getAccessToken()}`,
};

// export const keyHeaders = {
//   "client-key": "2acd1d9d-ef59-42bd-9710-cf000e6e33a7",
//   "secret-key": "1b1c7cee-6612-4e61-9878-4f4cd16d6e6f",
// };

// sagun;
// export const keyHeaders = {
//   "client-key": "79000269-290c-407b-9279-1b4a532ff21c",
//   "secret-key": "05621445-600d-486f-bac2-d48e404b7e88",
// };

// Bigyan;
// export const keyHeaders = {
//   "client-key": "e31a313d-e231-4b8e-a65f-63d7fedbd724",
//   "secret-key": "31cebd90-dc0b-4698-875c-b0d1b300de5e",
// };

//sagun 2
export const keyHeaders = {
  "client-key": "c20ba3ee-8a89-11ee-b9d1-0242ac120002",
  "secret-key": "d3043724-8a89-11ee-b9d1-0242ac120002",
};

export const recaptchaSiteKey = "6LdZtN8pAAAAANxxJKqOR1nqPUw0uapmgGpiAXd9";

export function getAccessToken() {
  return localStorage.getItem("token");
}

export const esewaUrl = "https://epay.esewa.com.np/epay/main";
//
// export const serverLocation = "http://192.168.1.82:8000",
// export const serverLocation = "http://192.168.1.102:8000",
// export const serverLocation = "http://localhost:8000",
export const serverLocation = "https://tajmahal-backend.techarttrekkies.com",
  // export const serverLocation = "http://localhost:8000",
  // export const serverLocation = "http://192.168.1.112:8000",
  // export const serverLocation = "http://192.168.1.135:8000",
  // export const serverLocation = "http://192.168.1.122:8000",
  // export const serverLocation = "https://backend.easyticketbookings.com",
  cryptoSecretKey = "techart@TravelAgency09",
  fileUrl = serverLocation + "/storage",
  // login
  loginUrl = `${serverLocation}/api/login`,
  // register
  registerURL = `${serverLocation}/api/register`,
  // esewa

  // URLS
  URL = {
    //roles and permissions

    getPermissions: `${serverLocation}/api/auth/getPermission`,
    getTravelAgencyWisePermission: `${serverLocation}/api/auth/getTravelAgencyWisePermission`,
    getRoles: `${serverLocation}/api/auth/getRoles`,
    updatePermissions: `${serverLocation}/api/auth/updateRoleAndPermission`,
    travelAgencyPermission: `${serverLocation}/api/auth/assignPermission`,

    buses: `${serverLocation}/api/auth/buses`,
    deleteBus: `${serverLocation}/api/auth/buses/delete`,
    busAgencies: `${serverLocation}/api/auth/bus-agencies`,
    deleteBusAgencies: `${serverLocation}/api/auth/bus-agencies/delete`,
    busRoutePoints: `${serverLocation}/api/auth/buses/buses-route-points`,
    getBusRouteAuthUser: `${serverLocation}/api/auth/buses/getBusRouteAuthUser`,
    busScheduling: `${serverLocation}/api/auth/buses/bus-schedule-setting`,
    busSeatConfiguration: `${serverLocation}/api/auth/buses/busSeatConfiguration`,
    busTypeSeatConfiguration: `${serverLocation}/api/auth/bus-types/bus-type-seat-configuration`,
    busTypes: `${serverLocation}/api/auth/bus-types`,
    updateBusTypes: `${serverLocation}/api/auth/bus-types/updateBusType`,
    busesTypes: `${serverLocation}/api/busTypes`,
    deleteBusTypes: `${serverLocation}/api/auth/bus-types/deleteBusType`,
    deleteBusScheduling: `${serverLocation}/api/auth/buses/remove-schedule-setting`,
    getAllBusSchedules: `${serverLocation}/api/auth/buses/get-all-schedule-setting`,
    getBusRoute: `${serverLocation}/api/auth/buses/get-bus-route`,
    getBusSeatConfiguration: `${serverLocation}/api/auth/buses/getBusSeatConfiguration`,
    getCities: `${serverLocation}/api/cities/get-all-cities`,
    getSingleBusTypeSeatConfiguration: `${serverLocation}/api/auth/bus-types/allSingleBusTypeSeatConfiguration`,

    networkAgency: `${serverLocation}/api/auth/network-agencies`,
    deleteNetworkAgency: `${serverLocation}/api/auth/network-agencies/delete`,

    routes: `${serverLocation}/api/auth/routes`,
    deleteRoutes: `${serverLocation}/api/auth/routes/delete/`,

    //search available bus

    searchAvailableBuses: `${serverLocation}/api/search-available-bus`,

    travelAgency: `${serverLocation}/api/auth/travel-agencies`,
    deleteTravelAgency: `${serverLocation}/api/auth/travel-agencies/delete`,
    getWebsiteTravelAgency: `${serverLocation}/api/current-travel-agency`,

    //travelChildAgency
    // travelAgency: `${serverLocation}/api/auth/travel-agencies`,
    travelChildAgency: `${serverLocation}/api/auth/child-travel-agencies`,
    deleteTravelChildAgency: `${serverLocation}/api/auth/child-travel-agencies/delete`,
    storeTravelChildAgency: `${serverLocation}/api/auth/child-travel-agencies/store`,

    travelAgencyRoutes: `${serverLocation}/api/auth/routes/travel-agency-routes`,
    updateBus: `${serverLocation}/api/auth/buses/update-bus`,
    updateBusAgency: `${serverLocation}/api/auth/bus-agencies/update-bus-agency`,
    updateNetworkAgency: `${serverLocation}/api/auth/network-agencies/update`,
    updateTravelAgency: `${serverLocation}/api/auth/travel-agencies/update-travel-agency`,
    //update child travel agency
    updateChildTravelAgency: `${serverLocation}/api/auth/child-travel-agencies/update-travel-agency`,

    updateRoute: `${serverLocation}/api/auth/routes/update-route`,

    // bus type seat configuration

    updateBusTypeSeatConfiguration: `${serverLocation}/api/auth/bus-types/update-bus-type-seat-configuration`,

    // bus seat configuration
    updateBusSeatConfiguration: `${serverLocation}/api/auth/buses/updateBusSeatConfiguration`,

    //route points
    deleteBusRoute: `${serverLocation}/api/auth/buses/bus-route/delete-bus-route`,

    insertRoutePoints: `${serverLocation}/api/auth/route-points`,
    updateRoutePoints: `${serverLocation}/api/auth/route-points/update`,

    // bus route points
    updateBusRoutePoints: `${serverLocation}/api/auth/buses/buses-route-points/update`,

    // daily schedule
    getDailySchedule: `${serverLocation}/api/auth/daily-schedules`,
    insertDailySchedule: `${serverLocation}/api/auth/daily-schedules`,
    deleteDailySchedule: `${serverLocation}/api/auth/daily-schedules/delete`,
    updateDailySchedule: `${serverLocation}/api/auth/daily-schedules/update-daily-schedules`,
    filterDailySchedule: `${serverLocation}/api/auth/daily-schedules/filter-daily-schedules`,

    //bus schedule setting
    updateBusScheduleSetting: `${serverLocation}/api/auth/buses/update-schedule-setting`,

    // Top Routes
    busTopRoutes: `${serverLocation}/api/top-bus-routes`,
    //Travel Agency Bus Type
    // busTypes: `${serverLocation}/api/bus-types`,

    //get Travel AGency
    getBusAgency: `${serverLocation}/api/bus-agency`,
    getTravelAgency: `${serverLocation}/api/network-agency`,

    // booking

    tempAuthBusSeatBook: `${serverLocation}/api/auth/temp_bus_seat_books`,
    tempBusSeatBook: `${serverLocation}/api/temp_bus_seat_books`,
    deleteTempBusSeatBook: `${serverLocation}/api/temp_bus_seat_books/delete`,

    getBookingBusSeatConfiguration: `${serverLocation}/api/buses/getBusSeatConfigurationForBooking`,
    getAuthBookingBusSeatConfiguration: `${serverLocation}/api/auth/buses/getBusSeatConfigurationForBooking`,

    getCustomerDetails: `${serverLocation}/api/auth/customer`,

    // boarding and dropping point

    getBoardingPointAndDroppingPoint: `${serverLocation}/api/auth/route-points/getBoardingPointAndDroppingPoint`,
    getGuestBoardingPointAndDroppingPoint: `${serverLocation}/api/getBoardingPointAndDroppingPoint`,
    // bus booking
    busBooking: `${serverLocation}/api/auth/bus-bookings`,
    downloadTicket: `${serverLocation}/api/downloadFile`,

    // No auth booking

    guestBusBooking: `${serverLocation}/api/bus-booking`,

    getKhaltiKeys: `${serverLocation}/api/auth/travel-agencies/khalti/keys`,
    verifyKhaltiPayment: `${serverLocation}/api/auth/payments/getVerification`,
    getTransactionDetail: `${serverLocation}/api/auth/payments/getTransactionDetail`,
    esewaVerification: `${serverLocation}/payments/esewaVerification/`,
    cancelledEsewaVerification: `${serverLocation}/payments/cancel/`,

    //My Tickets
    getMyTickets: `${serverLocation}/api/auth/bus-bookings`,

    // No auth
    getGuestKhaltiKeys: `${serverLocation}/api/travel-agencies/khalti/keys`,
    verifyGuestKhaltiPayment: `${serverLocation}/api/payments/getVerification`,
    getGuestTransactionDetail: `${serverLocation}/api/payments/getTransactionDetail`,

    //FAQ
    insertFAQ: `${serverLocation}/api/auth/travel-agency-faqs`,
    getFAQs: `${serverLocation}/api/auth/travel-agency-faqs/get-faqs`,
    deleteFAQs: `${serverLocation}/api/auth/travel-agency-faqs/delete`,
    updateFAQs: `${serverLocation}/api/auth/travel-agency-faqs/update`,
    // Guest FAQ
    getGuestFAQs: `${serverLocation}/api/travel-agency-faqs`,

    //Slider
    getSliderImages: `${serverLocation}/api/auth/slider-cms`,
    deleteSliderImage: `${serverLocation}/api/auth/slider-cms/delete`,
    insertSliderImages: `${serverLocation}/api/auth/slider-cms`,
    //GuestSlider
    getGuestSliderImages: `${serverLocation}/api/slider-cms`,

    //Terms and Conditions
    insertTermsAndCondition: `${serverLocation}/api/auth/term-and-conditions`,
    getTermsAndCondition: `${serverLocation}/api/auth/term-and-conditions`,
    deleteTermsAndConditions: `${serverLocation}/api/auth/term-and-conditions/delete`,
    updateTermsAndConditions: `${serverLocation}/api/auth/term-and-conditions/update`,
    //GuestTermsAndConditions
    getGuestTermsAndCondition: `${serverLocation}/api/term-and-conditions`,

    //blog
    blogs: `${serverLocation}/api/auth/blogs`,
    updateBlog: `${serverLocation}/api/auth/blogs/update`,
    deleteBlog: `${serverLocation}/api/auth/blogs/delete`,
    //guest blog
    getBlogs: `${serverLocation}/api/blogs`,
    //Legal Documents
    documentTypes: `${serverLocation}/api/auth/legal-documents`,
    deleteDocumentTypes: `${serverLocation}/api/auth/legal-documents/delete`,
    updateDocumentTypes: `${serverLocation}/api/auth/legal-documents/update`,

    //Payment Gateway
    paymentGateway: `${serverLocation}/api/auth/travel-agency-payment-keys`,
    deletePaymentGateway: `${serverLocation}/api/auth/travel-agency-payment-keys/delete`,
    updatePaymentGateway: `${serverLocation}/api/auth/travel-agency-payment-keys/update`,

    //getPayemntGateway
    getPaymentGateway: `${serverLocation}/api/travel-agency-payment-keys`,
    // legal documents
    getLegalDocs: `${serverLocation}/api/legal-documents`,

    //
    paymentReceipt: `${serverLocation}/api/paymentReceipt`,

    //get daily schedule info
    getDailyScheduleDetails: `${serverLocation}/api/auth/daily-schedules/getBookingInfo`,

    //contact page
    postContactData: `${serverLocation}/api/contact-us`,

    //postPaymentGAteway Global

    postGlobalPaymentConfig: `${serverLocation}/api/auth/payment-keys-configuration`,
    getGlobalPaymentGateway: `${serverLocation}/api/auth/payment-keys-configuration/get-configurations`,
    updateGlobalPaymentGateway: `${serverLocation}/api/auth/payment-keys-configuration/update`,
    deleteGlobalPaymentGateway: `${serverLocation}/api/auth/payment-keys-configuration/delete`,

    //customers
    getAllCustomers: `${serverLocation}/api/auth/customer/getAllCustomers`,
    noPaginationAllCustomers: `${serverLocation}/api/auth/customer/getAll`,

    //operators
    // getAllOperators: `${serverLocation}/api/auth/customer/`,

    //dashbaord counts
    getAllCountForNetworkAgency: `${serverLocation}/api/auth/travel-agencies/getAllUserCounts`,

    viewCancelTickets: `${serverLocation}/api/auth/bus-bookings/viewCancleTickets`,
    viewAllTickets: `${serverLocation}/api/auth/bus-bookings/viewAllTickets`,

    getAllTicketSales: `${serverLocation}/api/auth/bus-bookings/getAllTicketSales`,

    //cancel ticket
    cancelTicket: `${serverLocation}/api/auth/bus-bookings/cancelTicket`,

    //about us
    storeAboutUs: `${serverLocation}/api/auth/about-us`,
    updateAboutUs: `${serverLocation}/api/auth/about-us/update`,
    getAboutUs: `${serverLocation}/api/about-us`,

    getAuthAboutUs: `${serverLocation}/api/auth/about-us/index`,

    //import export data

    exportBookingData: `${serverLocation}/api/auth/export/ticket`,
    // importBookingData: `${serverLocation}/api/import/ticket`,

    //advertisements

    insertAdds: `${serverLocation}/api/auth/advertisement`,
    updateAdd: `${serverLocation}/api/auth/advertisement/update/`,
    getAllAdds: `${serverLocation}/api/auth/advertisement/index`,
    deleteAdd: `${serverLocation}/api/auth/advertisement/delete`,

    //social icons data

    insertSocialIcons: `${serverLocation}/api/auth/social-link`,
    getAllSocialIcons: `${serverLocation}/api/auth/social-link`,
    updateSocialIcons: `${serverLocation}/api/auth/social-link/update`,
    // deleteSocialIcons: `${serverLocation}/api/auth/social-link/delete`,

    //get map
    getMap: `${serverLocation}/api/auth/getMap`,
    getAllAddNoAuth: `${serverLocation}/api/advertisement`,

    // get data for export

    getExportData: `${serverLocation}/api/auth/bus-bookings/ticketFilter`,

    PostTicketStatusUpdate: `${serverLocation}/api/auth/bus-bookings/ticketStatusUpdate`,

    getAllTravelAgencies: `${serverLocation}/api/auth/travel-agencies/noPagination`,

    //get route points from travel agency and bus
    getRoutePointByTravelAgency: `${serverLocation}/api/auth/routes/get-all-travel-agency-routes-points`,

    getAmenitiesList: `${serverLocation}/api/auth/service`,
    getAmenitiesListNoAuth: `${serverLocation}/api/services`,
    createAmenitiesList: `${serverLocation}/api/auth/service/store`,
    updateAmenitiesList: `${serverLocation}/api/auth/service/update`,
    deleteAmenitiesList: `${serverLocation}/api/auth/service/delete`
  };

export const moduleName = {
  bus: "Bus",
  busAgency: "Bus Agency",
  busRoute: "Bus Route",
  busSeatConfiguration: "Bus Seat Configuration",
  busTypeAgency: "Bus Type Agency",
  busTypeSeatConfiguration: "Bus Type Seat Configuration",
  networkAgency: "Network Agency",
  route: "Route",
  routePoint: "Route Point",
  scheduleSetting: "Schedule Setting",
  dailySchedule: "Daily Schedule",
  travelAgency: "Travel Agency",
  travelAgencyRoute: "Travel Agency Route",
  travelChildAgency: "Child Travel Agency",
  rolePermission: "Role Permission",
  faq: "FAQ",
  slider: "Slider",
  termsAndConditions: "Term and Conditions",
  blog: "Blog",
  documents: "Legal Document",
  paymentGateway: "Payment Gateway",
  paymentGatewayConfig: "Payment Gateway Configuration",
  advertisement: "Advertisement",
  socialIcons: "Social Link",
  aboutUs: "About Us",
  ticket: "Ticket",
  manualBooking: "Manual Booking",
  dasboardBusBooking: "Bus Booking Dashboard",

  customers: "Customers",
  mapsData: "Maps Data",
  ticketExport: "Ticket Export",
  contactUs: "Contact Us",
  allTickets: "All Tickets",
  amenities: "Service"
};

export const seatConfig = {
  available: "is_open_for_booking",
  driver: "is_driver",
  empty: "is_empty",
};
