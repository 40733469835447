import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import swal from "sweetalert";
import CustomFullSpinner from "../../../components/WebsiteComponents/CustomFullSpinner";
import { axiosPost } from "../../../utils/AxiosApi";
import { URL } from "../../../utils/Constants";
import {
  displayErrorAlert,
  DISPLAYMESSAGE,
} from "../../../utils/DisplayMessage";

class AddDocumentTypes extends Component {
  state = {
    documentTypes: [{ document_type: "" }],
    spinner: false,
  };

  addRow = () => {
    this.setState({
      documentTypes: [...this.state.documentTypes, { document_type: "" }],
    });
  };

  componentDidMount() {
    if (this.props.edit) {
      this.setUpdateConfig();
    }
  }

  deleteRow = (idx) => {
    let documentTypes = [...this.state.documentTypes];
    documentTypes.splice(idx, 1);
    this.setState({ documentTypes });
  };

  handleCancel = () => {
    this.setState({ documentTypes: [{ document_type: "" }] });
  };

  handleChange = (e, idx) => {
    let { name, value } = e.target;
    let documentTypes = [...this.state.documentTypes];
    documentTypes[idx][name] = value;
    this.setState({ documentTypes });
  };

  handleSave = () => {
    let errChk = 0;

    this.state.documentTypes.forEach((dt) => {
      if (dt.document_type === undefined || dt.document_type === "") {
        errChk++;
      }
    });

    if (errChk > 0) {
      swal("Warning", "Document type cannot be empty.", "warning");
      this.setState({ spinner: false });

      return;
    } else {
      this.setState({ spinner: true }, function () {
        axiosPost(
          URL.documentTypes,
          this.state.documentTypes,
          (response) => {
            this.setState({ spinner: false });
            if (response.status === 200) {
              swal("Success", DISPLAYMESSAGE.createSuccess, "success");
              this.props.getDocumentTypes();
              this.props.handleCreateModal();
            }
          },
          (err) => {
            this.setState({ spinner: false });
            displayErrorAlert(err);
          }
        );
      });
    }
  };
  handleUpdate = () => {
    let errChk = 0;

    this.state.documentTypes.forEach((dt) => {
      if (dt.document_type === undefined || dt.document_type === "") {
        errChk++;
      }
    });

    if (errChk > 0) {
      swal("Warning", "Document type cannot be empty.", "warning");
      this.setState({ spinner: false });

      return;
    } else {
      this.setState({ spinner: true }, function () {
        let data = {
          id: this.props.selectedData.id,
          document_type: this.state.documentTypes[0].document_type,
        };
        axiosPost(
          URL.updateDocumentTypes,
          data,
          (response) => {
            this.setState({ spinner: false });
            if (response.status === 200) {
              swal("Success", DISPLAYMESSAGE.updateSuccess, "success");
              this.props.getDocumentTypes();
              this.props.handleCreateModal();
            }
          },
          (err) => {
            this.setState({ spinner: false });
            displayErrorAlert(err);
          }
        );
      });
    }
  };

  setUpdateConfig = () => {
    let documentType = this.props.selectedData;
    if (documentType) {
      let documentTypes = [];
      let tempData = {
        document_type: documentType.document_type,
      };
      documentTypes.push(tempData);
      this.setState({ documentTypes });
    }
  };

  render() {
    return (
      <div className="container">
        {this.state.spinner ? (
          <CustomFullSpinner text="Processing ..."></CustomFullSpinner>
        ) : null}
        <div className="routeMainHolder">
          <div className="container-fluid">
            {this.state.documentTypes.map((type, idx) => {
              return (
                <div className="row createRouteHolder" key={idx}>
                  <div className="col-md-11">
                    <div className="row">
                      <div className="col-md-4">
                        <strong>Document Type Name:
                          <span className="text-danger"> *</span>
                        </strong>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          className="form-control"
                          name="document_type"
                          value={type.document_type}
                          onChange={(e) => this.handleChange(e, idx)}
                        />
                      </div>
                    </div>
                  </div>
                  {this.props.edit ? null : (
                    <div className="col-md-1 pe-0">
                      {idx === this.state.documentTypes.length - 1 ? (
                        <FontAwesomeIcon
                          icon={faPlusCircle}
                          onClick={this.addRow}
                          style={{ marginRight: "5px", color: "#0b5ed7" }}
                        />
                      ) : null}
                      {idx == 0 ? null : (
                        <FontAwesomeIcon
                          icon={faMinusCircle}
                          style={{ color: "#bb2d3b" }}
                          onClick={() => this.deleteRow(idx)}
                        />
                      )}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <div className="row mt-3">
          <div className="col text-end">
            {this.props.edit ? (
              <button className="btn btn-primary" onClick={this.handleUpdate}>
                Update
              </button>
            ) : (
              <button className="btn btn-primary" onClick={this.handleSave}>
                Create
              </button>
            )}
            <button
              className="btn btn-danger"
              style={{ marginLeft: "10px" }}
              onClick={this.handleCancel}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default AddDocumentTypes;
