import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Select from "react-select";
import { fileUrl, keyHeaders } from "../../../utils/Constants";
import { Link } from "react-router-dom";

import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Col,
  Container,
  Card,
  CardImg,
  CardImgOverlay,
  CardTitle,
  Spinner,
} from "reactstrap";

import { axiosGet, axiosPost, axiosGetWeb } from "../../../utils/AxiosApi";
import {
  displayErrorAlert,
  DISPLAYMESSAGE,
} from "../../../utils/DisplayMessage";
import { faCross, faSync, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { URL } from "../../../utils/Constants";
import wave from "../../../assets/images/wave.png";
import whyus1 from "../../../assets/images/whyus (1).png";
import whyus2 from "../../../assets/images/whyus (2).png";
import whyus3 from "../../../assets/images/whyus (3).png";
import whyus4 from "../../../assets/images/whyus (4).png";
import PaymentPatners from "../PaymentPatners";
import { WithRouter } from "../../../utils/WithRouter";
import moment from "moment";
import axios from "axios";

class HomePage extends Component {
  state = {
    cities: [],
    cityName: "",
    sliderContents: [],
    topRoutes: [],
    loader: false,
    origin: "",
    destination: "",
    busDepartureDate: "",
    busTiming: "",
    adData: [],
    amenities: [],
  };

  getSliderImages = () => {
    this.setState({
      loader: true,
    });

    axiosGetWeb(
      URL.getGuestSliderImages,
      (response) => {
        if (response.status === 200) {
          this.setState({
            loader: false,
            sliderContents: response.data?.data?.items[0].media,
          });
        }
      },
      (err) => {
        this.setState({
          loader: false,
        });
      }
    );
  };

  getAllAdds = () => {
    axiosGetWeb(URL.getAllAddNoAuth, (response) => {
      if (response.status === 200) {
        this.setState({
          adData: response.data.data.items,
        });
      }
    });
  };

  getTopRoutes = () => {
    axiosGetWeb(
      URL.busTopRoutes,
      (response) => {
        if (response.status === 200) {
          this.setState({
            topRoutes: response.data.data.items[0],
          });
        }
      },
      (err) => {
        displayErrorAlert(err);
      }
    );
  };

  componentDidMount() {
    this.getCities();
    this.getSliderImages();
    this.getTopRoutes();
    this.getAllAdds();
    this.viewAmenities();
  }

  getCities = () => {
    this.setState({ cities: [] }, function () {
      axiosPost(
        URL.getCities,
        { name: "" },
        (response) => {
          if (response.status === 200) {
            let items = response.data.data.items;
            items.sort(function (a, b) {
              return a.name.localeCompare(b.name); //using String.prototype.localCompare()
            });
            this.setState({
              cities: items,
            });
          }
        },
        (err) => {
          displayErrorAlert(err);
        }
      );
    });
  };

  handleChange = (e) => {
    let { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleOriginChange = (e) => {
    this.setState({ origin: e });
  };

  handleDestinationChange = (e) => {
    this.setState({ destination: e });
  };

  handleOnClickExchangeIcon = () => {
    document.getElementById("exchangeIcon").style.animation =
      "rotate 1s infinite";
    setTimeout(
      () => (document.getElementById("exchangeIcon").style.animation = "none"),
      1000
    );

    this.setState({
      origin: this.state.destination,
      destination: this.state.origin,
    });
  };

  handleResetDepartureDate = () => {
    this.setState({
      busDepartureDate: "",
    });
  };


  viewAmenities = (pageNumber = 1) => {
    this.setState({ dataSpinner: true, routes: [] }, function () {
      axiosGetWeb(
        URL.getAmenitiesListNoAuth,
        (response) => {
          if (response.status === 200) {
            this.setState({
              amenities: response.data.data.items,
              dataSpinner: false,
            });
          }
        },
        (err) => {
          displayErrorAlert(err);
          this.setState({ dataSpinner: false });
        }
      );
    });
  }

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      className: "homeslider-container",
      adaptiveHeight: false,
      arrows: false,
    };

    const settings_aboutUS = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      className: "homeslider-container",
      adaptiveHeight: false,
      arrows: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ],
      autoplay: true,
      autoScroll: {
        speed: 1,
        rewind: true,
      },
    };

    const countOnes = this.state.amenities.map((item)=>item.status);
    const filterOnes = countOnes.filter((item)=>item === 1).length
    const result = filterOnes >= 4;

    return (
      <div>
        <Slider {...settings}>
          {/* <div
            className={
              this.state.loader
                ? "homeslider d-flex align-items-center justify-content-center"
                : "homeslider"
            }
          >
            {this.state.loader ? (
              <Spinner />
            ) : (
              this.state.sliderContents?.map((slider) => {
                return (
                  <img
                    src={fileUrl + "/" + slider.id + "/" + slider.file_name}
                    className="img-fluid"
                    alt="slider-preview"
                  ></img>
                );
              })
            )}
          </div> */}

          {this.state.loader ? (
            <div className="homeslider d-flex align-items-center justify-content-center">
              <Spinner />
            </div>
          ) : this.state.sliderContents.length > 0 ? (
            this.state.sliderContents?.map((slider) => {
              return (
                <div className="homeslider">
                  <img
                    src={fileUrl + "/" + slider.id + "/" + slider.file_name}
                    className="img-fluid"
                    alt="slider-preview"
                  ></img>
                </div>
              );
            })
          ) : (
            <div className="homeslider">
              <img
                src={whyus1}
                className="img-fluid"
                alt="slider-preview"
              ></img>
            </div>
          )}
        </Slider>
        <Container className="busTicketForm-container">
          <div className="busTicketForm-div">
            <div className="busTicketForm-title">Book Your Ticket</div>
            <Form className="busTicketForm">
              <Row className="align-items-center g-0">
                <Col className="m-0" sm="11">
                  <FormGroup>
                    <Label for="origin">From</Label>
                    <Select
                      options={this.state.cities}
                      name="origin"
                      value={this.state.origin}
                      onChange={(e) => this.handleOriginChange(e)}
                      placeholder="Select Origin City"
                      isLoading={this.state.isLoading}
                      isClearable
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="destination">To</Label>
                    <Select
                      options={this.state.cities}
                      name="destination"
                      value={this.state.destination}
                      onChange={(e) => this.handleDestinationChange(e)}
                      placeholder="Select Destination City"
                      isLoading={this.state.isLoading}
                      isClearable
                    />
                  </FormGroup>
                </Col>
                <Col className="m-0 p-0" sm="1">
                  <FontAwesomeIcon
                    icon={faSync}
                    id="exchangeIcon"
                    onClick={this.handleOnClickExchangeIcon}
                  />
                </Col>
              </Row>
              <Row className="mt-1">
                <Col className="m-0" md="11" sm="11">
                  <FormGroup>
                    <Label for="departureDate">Departure Date</Label>

                    <div className="d-flex">
                      <Input
                        id="busDepartureDate"
                        name="busDepartureDate"
                        type="date"
                        className="busDepartureDate"
                        value={this.state.busDepartureDate}
                        defaultValue={new Date().toISOString().substr(0, 10)}
                        onChange={this.handleChange}
                        min={moment(new Date()).format("YYYY-MM-DD")}
                      ></Input>
                      {this.state.busDepartureDate === "" ? null : (
                        <FontAwesomeIcon
                          onClick={this.handleResetDepartureDate}
                          icon={faTimes}
                          style={{
                            position: "relative",
                            right: "60",
                            top: "12",
                          }}
                        />
                      )}
                    </div>
                  </FormGroup>
                </Col>

                <Col className="m-0" md="12" sm="12">
                  <Label for="departureDate">Shift Timing</Label>
                  <FormGroup tag="fieldset" className="radio-container">
                    <FormGroup check>
                      <Input
                        name="busTiming"
                        type="radio"
                        value="Day"
                        id="day"
                        onChange={this.handleChange}
                      />
                      <Label for="day" className="radioLabel">
                        Day
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Input
                        name="busTiming"
                        type="radio"
                        value="Night"
                        id="night"
                        onChange={this.handleChange}
                      />
                      <Label for="night" className="radioLabel">
                        Night
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Input
                        name="busTiming"
                        type="radio"
                        value="Both"
                        id="both"
                        onChange={this.handleChange}
                      />
                      <Label for="both" className="radioLabel">
                        Both
                      </Label>
                    </FormGroup>
                  </FormGroup>
                </Col>
              </Row>

              <Link
                to="/routesList"
                state={{
                  route: {
                    destination: this.state.destination,
                    origin: this.state.origin,
                    busDepartureDate: this.state.busDepartureDate,
                    busTiming: this.state.busTiming,
                  },
                }}
                className="text-decoration-none"
              >
                <Button className="busTicketForm-btn">
                  Check Availability
                </Button>
              </Link>
            </Form>
          </div>
        </Container>
        <Container fluid className="whyUs-section">
          {/* <div className="section-title" style={{ backgroundImage: `url(${wave})` }}> */}
          <div className="section-title">
            <img src={wave} />
            <h1>Why Us?</h1>
          </div>

          {this.state.amenities && this.state.amenities.length >= 4 && result  ?
            (
              <>
                <Container>
                  <Row className="py-5 g-0 amenitites_slider">
                    <Slider {...settings_aboutUS}>
                      {this.state.amenities.length > 0 ? (
                        this.state.amenities.map((item) => {
                          return (
                            <Col className="m-0" sm="6" md="3" key={item.id}>
                              <div className="whyUs-item d-flex justify-content-center align-items-center flex-column">
                                {item.media.length ?
                                <img src={`${fileUrl}/${item.media[0].id}/${item.media[0].file_name}`} />
                              :""}
                                <h4>{item.title}</h4>
                                <p>{item.description}</p>
                              </div>
                            </Col>
                          );
                        })
                      ) : (
                        <div className="homeslider">
                          <img src={whyus1} className="img-fluid" alt="slider-preview" />
                        </div>
                      )}
                    </Slider>
                  </Row>
                </Container>
              </>) :
            (
              <>
                <Container>
                  <Row className="py-5 g-0">
                    <Col className="m-0" sm="6" md="3">
                      <div className="whyUs-item">
                        <img src={whyus2} />
                        <h4>Excellent Customer Service</h4>
                        <p>
                          Making each and every aspect of the customer's experience an
                          absolutely positive one
                        </p>
                      </div>
                    </Col>
                    <Col className="m-0" sm="6" md="3">
                      <div className="whyUs-item">
                        <img src={whyus1} />
                        <h4>No extra charges</h4>
                        <p>
                          Without any extra fees, we make it easy to book your
                          tickets.
                        </p>
                      </div>
                    </Col>
                    <Col className="m-0" sm="6" md="3">
                      <div className="whyUs-item">
                        <img src={whyus3} />
                        <h4>Best Travel Options</h4>
                        <p>
                          We help you find & compare the best buses & trains in one
                          place.
                        </p>
                      </div>
                    </Col>
                    <Col className="m-0" sm="6" md="3">
                      <div className="whyUs-item">
                        <img src={whyus4} />
                        <h4>Cleaniness</h4>
                        <p>Keeping your travel safe and clean is our #1 priority.</p>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </>)}

        </Container>
        {/* <section className="CTA-section">
          {this.state.adData?.map(
            (ad) =>
              ad.type === "home-page-top" && (
                <Container className="">
                  <Row className="py-5 g-0 align-items-center">
                    <Col className="" sm="8" md="8" sm="12">
                      <h1>{ad.name}</h1>
                      <p>{ad.description}</p>
                    </Col>

                    <Col className="" sm="4" md="4" sm="12">
                      <div
                        className=" rounded-pill  p-4 bg-info d-block m-auto shadow-lg"
                        style={{ width: "200px" }}
                      >
                        <img
                          className="img-fluid"
                          src={
                            fileUrl +
                            "/" +
                            ad.media[0].id +
                            "/" +
                            ad.media[0].file_name
                          }
                        />
                      </div>
                    </Col>
                  </Row>
                </Container>
              )
          )}
        </section> */}
        {this.state.adData?.some((ad) => ad.type === "home-page-top") && (
          <section className="CTA-section-add-banner">
            {this.state.adData
              .filter((ad) => ad.type === "home-page-top" && ad.display)
              .map((ad) => (
                <a href={ad.link} target="_blank">
                  <div className="ad-banner" key={ad.id}>
                    <div className="ad-desc">
                      <h1>{ad.name}</h1>
                      <p>{ad.description}</p>
                    </div>
                    <img
                      className=""
                      src={`${fileUrl}/${ad.media[0].id}/${ad.media[0].file_name}`}
                      alt="No preview available"
                    />
                  </div>
                </a>
              ))}
          </section>
        )}

        <section className="routes-section">
          <Container className="">
            <Row>
              <Col className="" sm="12" md="12">
                <h1>Top Bus Routes</h1>
              </Col>

              {this.state.topRoutes ? (
                this.state.topRoutes?.length > 0 ? (
                  this.state.topRoutes?.map((route, idx) => {
                    return (
                      <Col
                        key={idx}
                        className="card-col"
                        xs="6"
                        sm="6"
                        md="4"
                        lg="4"
                        xl="4"
                        xxl="3"
                      >
                        <Card className="ratio ratio-1x1">
                          <CardImg
                            alt="Card image cap"
                            src={
                              route.media
                                ? fileUrl +
                                "/" +
                                route.media.id +
                                "/" +
                                route.media.file_name
                                : "https://nepaltourismhub.com/illion-asset/uploads/2019/12/848-Beautiful-Hetauda-city1.jpg"
                            } //change later with apiREsponse
                            width="100%"
                            className=""
                          />
                          <CardImgOverlay>
                            <Link
                              to="/routesList"
                              state={{ route: route }}
                              className="text-decoration-none"
                            >
                              <CardTitle className="text-uppercase">
                                {`${route.origin.name} - ${route.destination.name}`}
                              </CardTitle>
                            </Link>
                          </CardImgOverlay>
                        </Card>
                        {/* <div className="d-flex justify-content-center">
                        <Link
                          to="/routesList"
                          state={{ route: route }}
                          className="text-decoration-none"
                        >
                          <CardTitle className="text-uppercase" tag="h6">
                            {`${route.origin.name} - ${route.destination.name}`}
                          </CardTitle>
                        </Link>
                      </div> */}
                      </Col>
                    );
                  })
                ) : (
                  <div className="text-center">No Routes added yet!!</div>
                )
              ) : (
                ""
              )}
            </Row>
          </Container>
        </section>
        {this.state.adData?.some((ad) => ad.type === "home-page-bottom") && (
          <section className="CTA-section-add-banner">
            {this.state.adData
              .filter((ad) => ad.type === "home-page-bottom" && ad.display)
              .map((ad) => (
                <a href={ad.link} target="_blank">
                  <div className="ad-banner" key={ad.id} href={ad.link}>
                    <div className="ad-desc">
                      <h1>{ad.name}</h1>
                      <p>{ad.description}</p>
                    </div>
                    <img
                      className=""
                      src={`${fileUrl}/${ad.media[0].id}/${ad.media[0].file_name}`}
                      alt="No preview available"
                    />
                  </div>
                </a>
              ))}
          </section>
        )}

        {/* <section className="partners-section">
          <Container className="">
            <Row className="g-3">
              <Col className="" sm="12" md="12" sm="12">
                <h1>Our Payment Partners</h1>
              </Col>
              {this.state.payPartners.map((route, idx) => {
                return (
                  <Col className="card-col" xs="6" md="3" lg="3" key={idx}>
                    <div className="img-container ratio ratio-1x1">
                      {" "}
                      <img src={route.img} className="" />
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Container>
        </section> */}

        <PaymentPatners disableClickAction={true} />
      </div>
    );
  }
}

export default HomePage;
