import React, { Component } from "react";
import { Spinner, Tooltip } from "reactstrap";
import { axiosGet, axiosPost } from "../../../utils/AxiosApi";
import { URL, moduleName } from "../../../utils/Constants";
import CustomModal from "../../../components/WebsiteComponents/CustomModal";
import swal from "sweetalert";
import { fileUrl } from "../../../utils/Constants";

import {
  displayConfirmDeleteAlert,
  displayErrorAlert,
  DISPLAYMESSAGE,
} from "../../../utils/DisplayMessage";
import CreateRoute from "./CreateRoute";
import { checkRolePermissions } from "../../../utils/Helper";
import CreateRoutePoints from "../TravelAgency/CreateRoutePoints";

import sampleImg from "../../../assets/images/logo.jpg";
import CustomFullSpinner from "../../../components/WebsiteComponents/CustomFullSpinner";
import Pagination from "react-js-pagination";

class RouteView extends Component {
  state = {
    loader: false,
    cities: [],
    createModal: false,
    dataSpinner: false,
    deleteRoutePermission: false,
    routes: [],
    routePointModal: false,
    selectedRouteId: "",
    updateReqData: "",
    current_page: 1,
    totalItemsCount: 0,
    paginated_value: 10,
  };

  closeRoutePointModal = () => {
    this.setState({ routePointModal: !this.state.routePointModal });
  };

  componentDidMount() {
    this.getRoutes();
    this.getCities();
    this.permissions();
  }

  getCities = () => {
    axiosPost(URL.getCities, { name: "" }, (response) => {
      if (response.status === 200) {
        let items = response.data.data.items;
        items.sort(function (a, b) {
          return a.name.localeCompare(b.name); //using String.prototype.localCompare()
        });
        this.setState({ cities: items });
      }
    });
  };

  getRoutes = (pageNumber, paginated_value) => {
    let param = {
      page: pageNumber ? pageNumber : 1,
      paginated_value: paginated_value ? paginated_value : 10,
    }
    this.setState({ dataSpinner: true, routes: [] }, function () {
      axiosPost(
        URL.routes + "/" + "get-routes", param,
        (response) => {
          if (response.status === 200) {
            this.setState({
              routes: response.data.data.items,
              totalItemsCount: response.data.data.totalData,
              current_page: pageNumber,
              dataSpinner: false,
            });
          }
        },
        (err) => {
          displayErrorAlert(err);
          this.setState({ dataSpinner: false });
        }
      );
    });
  };

  handleCreateModal = () => {
    this.setState({
      createModal: !this.state.createModal,
      edit: false,
      updateReqData: "",
    });
  };

  handleDeleteBtnClick = (e, id) => {
    let params = { id: id };
    displayConfirmDeleteAlert(params, this.handleDelete);
  };

  handleDelete = (params) => {
    this.setState({ loader: true }, () => {
      axiosGet(
        URL.deleteRoutes + "global-routes/" + params.id,
        (response) => {
          if (response.status === 200) {
            swal("Success", DISPLAYMESSAGE.deleteSuccess, "success");
            this.getRoutes();
            this.setState({ spinner: false, loader: false });
          }
        },
        (err) => {
          displayErrorAlert(err);
          this.setState({ spinner: false, loader: false });
        }
      );
    });
  };

  handleRoutePoints = (id) => {
    this.setState({
      selectedRouteId: id,
      routePointModal: !this.state.routePointModal,
    });
  };

  permissions = () => {
    this.setState({
      deleteRoutePermission: checkRolePermissions("delete", moduleName.route),
    });
  };

  handleUpdateModal = (item) => {
    this.setState({
      createModal: !this.state.createModal,
      edit: true,
      updateReqData: item,
    });
  };

  handleUpdateBtnClick = (route) => {
    this.setState({
      editMode: true,
      routePointModal: true,
      updateReqData: route,
      selectedRouteId: route.id,
    });
  };

  handleRoutePoints = (id) => {
    this.setState({
      selectedRouteId: id,
      routePointModal: !this.state.routePointModal,
    });
  };

  handlePaginatedValueChange = (value) => {
    let valuePage = parseInt(value);
    this.setState({ paginated_value: valuePage }, () => {
      this.getRoutes(1, valuePage);
    });
  };

  render() {
    const { current_page, paginated_value, totalItemsCount } = this.state;

    return (
      <>
        {this.state.loader ? (
          <CustomFullSpinner text="Processing ..."></CustomFullSpinner>
        ) : null}
        <div className="dashboardHeader">
          <strong>ROUTES LIST</strong>
        </div>
        <div className="container-fluid h-100">
          <div className="row mt-2">
            <div className="col">
              {checkRolePermissions("create", moduleName.route) ? (
                <button
                  className="btn btn-success"
                  onClick={this.handleCreateModal}
                >
                  Add Routes
                </button>
              ) : null}
            </div>
          </div>
          <div className="row mt-2 h-100">
            <div className="col d-flex align-items-center justify-content-between flex-column">
              <div className="table-responsive w-100">
                <table className="table table-striped  text-center">
                  <thead>
                    <tr>
                      <th>S.N</th>
                      <th width="300px">Image</th>
                      <th>Origin City</th>
                      <th>Destination City</th>
                      <th>Documents Required</th>
                      {/* <th>Route Points</th> */}
                      {this.state.deleteRoutePermission ? (
                        <th width="300px">Options</th>
                      ) : null}
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.routes?.length > 0 ? (
                      this.state.routes?.map((route, idx) => {
                        return (
                          <tr key={idx}>
                            <td>{idx + 1}</td>
                            <td>
                              <img
                                style={{ width: "50%" }}
                                src={
                                  route.media?.length > 0
                                    ? fileUrl +
                                    "/" +
                                    route.media[0]?.id +
                                    "/" +
                                    route.media[0]?.file_name
                                    : sampleImg
                                }
                                alt="No preview"
                              />
                            </td>

                            <td>
                              {route.origin_city.country_code} -{" "}
                              {route.origin_city.name}
                            </td>
                            <td>
                              {route.destination_city.country_code} -{" "}
                              {route.destination_city.name}
                            </td>
                            <td>
                              {route.is_legal_document_required == 0
                                ? "No"
                                : "Yes"}
                            </td>

                            {this.state.deleteRoutePermission && route?.can_update ? (
                              <td>
                                {/* {route?.route_points?.length > 0 ? (
                                  <button
                                    className="btn btn-primary"
                                    onClick={() =>
                                      this.handleUpdateBtnClick(route)
                                    }
                                  >
                                    Configure Route Points
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-info m-1 text-white"
                                    onClick={() =>
                                      this.handleRoutePoints(route.id)
                                    }
                                  >
                                    Add Route Points
                                  </button>
                                )}
                                <br /> */}
                                <button
                                  disabled={route?.can_update ? false : true}
                                  className="btn btn-info m-1"
                                  onClick={() => this.handleUpdateModal(route)}
                                >
                                  <i class="fa-solid fa-pen-to-square"></i>
                                </button>
                                <button
                                  disabled={route?.can_update ? false : true}
                                  className="btn btn-danger m-1"
                                  onClick={(e) =>
                                    this.handleDeleteBtnClick(e, route.id)
                                  }
                                >
                                  <i class="fa-solid fa-trash"></i>
                                </button>
                              </td>
                            ) : <td className="text-info">Default Route, cannot update or delete.</td>}
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td
                          colSpan={this.state.deleteRoutePermission ? 6 : 5}
                          className="text-center p-3"
                        >
                          {this.state.dataSpinner ? (
                            <>
                              <Spinner color="primary"></Spinner>
                              <br />
                              Loading Data...
                            </>
                          ) : (
                            "No Routes found"
                          )}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {this.state.routes.total <= 10 ? (
                <></>
            ) : (
                <div className="paginationContainer">
                  <Pagination
                    activePage={current_page}
                    itemsCountPerPage={paginated_value}
                    totalItemsCount={totalItemsCount}
                    onChange={(pageNumber) => this.getRoutes(pageNumber, paginated_value)}
                    itemClass="page-item"
                    linkClass="page-link"
                    firstPageText="First"
                    lastPageText="Last"
                  />
                  <div className="paginatoion_paginated_value ml-3">
                    <select
                      className="form-control"
                      value={this.state.paginated_value}
                      onChange={(e) => this.handlePaginatedValueChange(e.target.value)}
                    >
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={30}>30</option>
                      <option value={40}>40</option>
                      <option value={50}>50</option>
                    </select>
                  </ div>
                </div>
              )}
            </div>
          </div>

          <CustomModal
            modal={this.state.createModal}
            modalBody={
              <CreateRoute
                edit={this.state.edit}
                updateReqData={this.state.updateReqData}
                getRoutes={this.getRoutes}
                handleCreateModal={this.handleCreateModal}
                cities={this.state.cities}
              ></CreateRoute>
            }
            modalHeader={this.state.edit ? "Edit Routes" : "Add Routes"}
            size="lg"
            toggleModal={this.handleCreateModal}
          ></CustomModal>

          <CustomModal
            modal={this.state.routePointModal}
            modalBody={
              <CreateRoutePoints
                editMode={this.state.editMode}
                routeId={this.state.selectedRouteId}
                getRoutes={this.getRoutes}
                closeRoutePointModal={this.closeRoutePointModal}
                updateReqData={this.state.updateReqData}
              ></CreateRoutePoints>
            }
            modalHeader={
              this.state.editMode ? "Edit Route Points" : "Add Route Points"
            }
            size="lg"
            toggleModal={this.closeRoutePointModal}
          ></CustomModal>
        </div>
      </>
    );
  }
}

export default RouteView;
