import React, { useEffect, useRef, useState } from "react";
import driver from "../../../assets/images/driver.svg";
import empty from "../../../assets/images/empty.svg";
import available from "../../../assets/images/available.svg";
import unavailable from "../../../assets/images/unavailable.svg";
import selected from "../../../assets/images/selected.svg";
import booked from "../../../assets/images/booked.svg";
import { axiosPost } from "../../../utils/AxiosApi";
import { URL } from "../../../utils/Constants";
import Countdown from "react-countdown";
import Select from "react-select";
import airHorn from "../../../assets/audio/air-horn.mp3"

import { FormGroup, Label, Popover, PopoverBody } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBus, faBusAlt } from "@fortawesome/free-solid-svg-icons";
import swal from "sweetalert";
import PaymentPatners from "../PaymentPatners";

const BookSeat = ({
  seatConfig,
  selectedSeats,
  selectedScheduleId,
  step,
  header,
  handleStepChangeByOne,
  selectedRoute,
  handleOnClickBusSeatSelect,
  isChanged,
  newSeatConfig,
  boardingPoints,
  droppingPoints,
  handleDroppingPointChange,
  handleBoardingPointChange,
  chosenBoardingPoint,
  chosenDroppingPoint,
  refreshSeat,
  manualBooking,
  handleManualBooking,
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const [remarks, setRemarks] = useState("");

  const [seatRemarks, setSeatRemarks] = useState([]);

  // for seat select status
  const [lastClickedIdx, setLastClickedIdx] = useState(null);
  const [lastClickedIndex, setLastClickedIndex] = useState(null);
  const [lastClickedBusRowId, setLastClickedBusRowId] = useState(null);
  const [lastClickedBusSeatId, setLastClickedBusSeatId] = useState(null);

  // state for storing clicked seats
  const [clickHistory, setClickHistory] = useState([]);
  const [isAnySeatSelected, setIsAnySeatSelected] = useState(false);
  const [seatsArray, setSeatsArray] = useState([])
  const [busHorn, setBusHorn] = useState(new Audio(airHorn))

  const handleClick = (e, idx, index, busRowId, busSeatId) => {

    const newClick = { idx, index, busRowId, busSeatId };

    const isNewClickInHistory = clickHistory.some(click => {
      return (
        click.idx === newClick.idx &&
        click.index === newClick.index &&
        click.busRowId === newClick.busRowId &&
        click.busSeatId === newClick.busSeatId
      );
    });

    const clicked = !isNewClickInHistory;

    const updatedClick = { ...newClick, clicked };

    if (clicked) {
      setClickHistory([...clickHistory, updatedClick]);
    } else {
      setClickHistory(clickHistory.filter(click =>
        !(click.idx === newClick.idx &&
          click.index === newClick.index &&
          click.busRowId === newClick.busRowId &&
          click.busSeatId === newClick.busSeatId)
      ));
    }


    setIsAnySeatSelected(clickHistory.length > 0 || clicked);
    setSeatsArray(prevState => [...prevState, updatedClick]);

    handleOnClickBusSeatSelect(e, idx, index, busRowId, busSeatId, isNewClickInHistory, updatedClick.clicked);
    // handleOnClickBusSeatSelect( e,idx,index,busRowId,busSeatId,isNewClickInHistory,[...clickHistory, updatedClick]);
  };

  const totalFareCalculate = () => {
    if (JSON.parse(localStorage?.getItem("selectedSeat")) !== null) {
      return (
        JSON.parse(localStorage?.getItem("selectedSeat")).length *
        selectedRoute.fare
      );
    } else {
      return 0;
    }
  };

  const handleContinueBooking = () => {
    let localStorageValue = JSON.parse(localStorage.getItem("selectedSeat"));
    if (localStorageValue !== null && localStorageValue.length > 0) {
      handleStepChangeByOne();
    } else {
      swal("Warning", "Please select seats to book.", "warning");
      return;
    }
  };

  const handleMouseOver = () => {
    setIsHovering(true);
  };
  const handleMouseOut = () => {
    setIsHovering(false);
  };
  let finalSeatConfig = seatConfig;
  // const finalSeatConfig = isChanged ? newSeatConfig : seatConfig;

  const renderBusSeatImages = (busSeat) => {
    let checkValue = {
      row_id: busSeat.bus_row_id,
      column_id: busSeat.id,
    };
    if (busSeat.is_booked) {
      return <img src={booked} alt="booked" />;
    } else if (busSeat.is_selected) {
      let localStorageValue = JSON.parse(localStorage.getItem("selectedSeat"));
      if (localStorageValue && localStorageValue.length > 0) {
        let exist = false;
        localStorageValue.find((datum) => {
          if (
            datum.row_id == checkValue.row_id &&
            checkValue.column_id == datum.column_id
          )
            exist = true;
        });
        if (exist) {
          return <img src={selected} alt="selected" />;
        } else {
          return <img src={unavailable} alt="unavailable" />;
        }
      } else {
        return <img src={unavailable} alt="unavailable" />;
      }
    } else {
      return <img src={available} alt="available" />;
    }
  };

  const renderPointers = (busSeat) => {
    let localStorageValue = JSON.parse(localStorage.getItem("selectedSeat"));
    let checkValue = {
      row_id: busSeat.bus_row_id,
      column_id: busSeat.id,
    };
    if (busSeat.is_booked) {
      return { pointerEvents: "none" };
    } else if (busSeat.is_selected) {
      if (localStorageValue) {
        let exist = false;
        localStorageValue.find((datum) => {
          if (
            datum.row_id == checkValue.row_id &&
            checkValue.column_id == datum.column_id
          )
            exist = true;
        });
        if (exist) return { pointerEvents: "auto" };
        else return { pointerEvents: "none" };
      } else {
        return { pointerEvents: "none" };
      }
      // if (userSeatConfig.find((us) => us.bus_column_id === busSeat.id)) {
      //   return { pointerEvents: "auto" };
      // } else {
      //   return { pointerEvents: "none" };
      // }
    } else {
      return { pointerEvents: "auto" };
    }
  };

  const handleSeatRemarks = (e, idx) => {
    const newSeatRemarks = [...seatRemarks];
    newSeatRemarks[idx] = e.target.value;
    setSeatRemarks(newSeatRemarks);

    let seatsArr = JSON.parse(localStorage.getItem("selectedSeat"));

    seatsArr.forEach((sa, idx) => {
      seatRemarks.forEach((sr, sidx) => {
        if (sidx === idx) {
          sa.remarks = sr;
        }
      });
    });

    localStorage.setItem("selectedSeat", JSON.stringify(seatsArr));
  };

  const onclickHorn =() =>{
    // let hornAudio = new Audio(airHorn);
    busHorn.play();
  }

  return (
    <>
      <div className="row mt-3">
        <div className="col-lg-5 ">
          <div className="vechile-container2">
            <div id="main_layer">
              {/* seats */}
              <div id="bus">
                <div id="bus_body" className="cls-2">
                  <div className="seat-container">
                    <table className="table table-borderless mb-0">
                      <tbody>
                        {finalSeatConfig.bus_rows.map((busRow, idx) => {
                          return (
                            <tr key={idx}>
                              {busRow.bus_columns.map((busSeat, index) => {
                                return (
                                  <td className="p-0" key={index}>
                                    {(busSeat.is_driver == 1) &
                                      (busSeat.is_open_for_booking == 0) &
                                      (busSeat.is_passage == 0) ? (
                                      <div className="seat-item">
                                        <img src={driver} alt="driver" />
                                      </div>
                                    ) : (busSeat.is_driver == 0) &
                                      (busSeat.is_open_for_booking == 0) &
                                      (busSeat.is_passage == 1) ? (
                                      <div className="seat-item">
                                        <img src={empty} alt="empty" />
                                      </div>
                                    ) : (busSeat.is_driver == 0) &
                                      (busSeat.is_open_for_booking == 1) &
                                      (busSeat.is_passage == 0) ? (
                                      <div
                                        style={renderPointers(busSeat)}
                                        className="seat-item"
                                        // onClick={(e) =>{
                                        //   setLastClickedIdx(idx);
                                        //   setLastClickedIndex(index);
                                        //   setLastClickedBusRowId(busRow.id);
                                        //   setLastClickedBusSeatId(busSeat.id);
                                        //   let clicked_seats = {};
                                        //   if (idx === lastClickedIdx && index === lastClickedIndex && busRow.id === lastClickedBusRowId && busSeat.id === lastClickedBusSeatId) {
                                        //     console.log('true');
                                        //     handleOnClickBusSeatSelect(e,idx,index,busRow.id,busSeat.id,true);
                                        //   } else {
                                        //     console.log('false');
                                        //     handleOnClickBusSeatSelect(e,idx,index,busRow.id,busSeat.id,false);
                                        //   }
                                        // }
                                        // }
                                        onClick={(e) => handleClick(e, idx, index, busRow.id, busSeat.id)}
                                      >
                                        <span className="seat-id">
                                          {/* {busRow.name} */}
                                          {busSeat.name}
                                        </span>

                                        {renderBusSeatImages(busSeat)}
                                      </div>
                                    ) : (
                                      <div className="seat-item">
                                        <span className="seat-id">
                                          {/* {busRow.name} */}
                                          {busSeat.name}
                                        </span>
                                        <img src={booked} alt="booked" />
                                      </div>
                                    )}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* seats end*/}
              <div id="mirrors">
                <div id="mirror" className="cls-1" />
                <div id="mirror-2" className="cls-1" />
              </div>
              <div id="deck">
                <div id="deck-2" className="cls-3" />
                <div id="driverdeck" className="cls-4" />
                <div id="steer-box" className="cls-5">
                  <div id="steer">
                    <div id="steer_b">
                      <div id="steer_c">
                        <div id="steer_d"></div>
                        <div id="beep"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="horn" onClick={onclickHorn}   style={{ cursor: "pointer" }}></div>
              </div>
              <div id="wheel-box">
                <div id="wheel" className="cls-1" />
                <div id="wheel-2" className="cls-1" />
                <div id="wheel-3" className="cls-1" />
                <div id="wheel-4" className="cls-1" />
              </div>
            </div>
          </div>
          <div className="busLabels">
            <div>
              <img src={booked} alt="booked" />
              <strong>Booked</strong>
            </div>
            <div>
              <img src={available} alt="available" />
              <strong>Available</strong>
            </div>
            <div>
              <img src={selected} alt="selected" />
              <strong>Selected</strong>
            </div>{" "}
            <div>
              <img src={driver} alt="selected" />
              <strong>Driver</strong>
            </div>{" "}
            <div>
              <img src={unavailable} alt="selected" />
              <strong>Unavailable</strong>
            </div>
          </div>
        </div>

        {/* For manual booking */}
        {manualBooking ? (
          <div className="col-lg-7">
            <table className="table table-sm table-borderless text-center">
              <thead>
                <tr>
                  <th>Booked Seat</th>
                  <th>
                    Remarks{" "}
                    <small style={{ fontSize: "10px", fontWeight: 400 }}>
                      (*Optional)
                    </small>
                  </th>
                </tr>
              </thead>
              <tbody>
                {JSON.parse(localStorage.getItem("selectedSeat"))?.map(
                  (item, idx) => (
                    <tr key={idx}>
                      <td>{item.columnName}</td>
                      <td>
                        {" "}
                        <input
                          className="form-control mb-2"
                          type="text"
                          name="seatRemarks"
                          value={seatRemarks[idx]}
                          onChange={(e) => handleSeatRemarks(e, idx)}
                        />
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
            {JSON.parse(localStorage.getItem("selectedSeat"))?.length > 0 && (
              <>
                <div className="remarksDiv">
                  <b>
                    Remarks{" "}
                    <small
                      style={{
                        fontSize: "10px",
                        fontWeight: 400,
                        color: "red",
                      }}
                    >
                      (*Mandatory)
                    </small>
                  </b>
                  <textarea
                    className="form-control mb-2"
                    type="text"
                    name="remarks"
                    onChange={(e) => setRemarks(e.target.value)}
                    value={remarks}
                  />
                </div>
                <div className="mt-3 text-end">
                  <button
                    style={{ backgroundColor: "#6C63FF", border: "none" }}
                    className="btn btn-md btn-primary p-2"
                    onClick={() => handleManualBooking(remarks)}
                  >
                    Book Seat
                  </button>
                </div>
              </>
            )}

            {/* <div className="bookedSeatsManual">
              <b>Seats : </b>
              <div>
                {JSON.parse(localStorage.getItem("selectedSeat"))?.map(
                  (item, idx) => (
                    <span>{item.columnName}, </span>
                  )
                )}
              </div>

              <div>
                <b>Remarks</b>
                <input
                  className="form-control"
                  name="remarks"
                  value={remarks}
                  onChange={handleChangeRemarks}
                />
              </div>

              <div className="mt-3">
                <button
                  style={{ backgroundColor: "#6C63FF", border: "none" }}
                  className="btn btn-md btn-primary p-2"
                  // onClick={() => handlePassengerDetailsCheck()}
                >
                  Book Seat
                </button>
              </div>
            </div> */}
          </div>
        ) : (
          <div className="col-lg-7 ">
            <div className="d-flex justify-content-between">
              <div className="p-1">
                <FormGroup>
                  <Select
                    options={boardingPoints}
                    name="chosenBoardingPoint"
                    value={chosenBoardingPoint}
                    onChange={(e) => handleBoardingPointChange(e)}
                    placeholder="Boarding point"
                    isClearable
                  // theme={(theme) => ({
                  //   ...theme,
                  //   borderRadius: 0,
                  //   colors: {
                  //     primary25: "#FF9190",
                  //     primary: "#6C63FF",
                  //   },
                  // })}
                  />
                </FormGroup>
              </div>
              <div className="p-1">
                <FormGroup>
                  <Select
                    options={droppingPoints}
                    name="chosenDroppingPoint"
                    value={chosenDroppingPoint}
                    onChange={(e) => handleDroppingPointChange(e)}
                    placeholder="Dropping point"
                    isClearable
                  // theme={(theme) => ({
                  //   ...theme,
                  //   borderRadius: 0,
                  //   colors: {
                  //     primary25: "#FF9190",
                  //     primary: "#6C63FF",
                  //   },
                  // })}
                  />
                </FormGroup>
              </div>
              <div className="p-1">
                <button
                  id="typesPop"
                  className="btn btn-primary"
                  onMouseOver={handleMouseOver}
                  onMouseOut={handleMouseOut}
                  style={{ backgroundColor: "#6C63FF", border: "none" }}
                >
                  Features
                </button>
              </div>
            </div>
            {isHovering ? (
              <div className="typesDisplay">
                <div className="p-2">{seatConfig.feature}</div>
              </div>
            ) : null}

            <div className="shadow p-3">
              <div className="row">
                <div className="col-12 d-flex justify-content-center mb-4">
                  <div>
                    <strong> {selectedRoute.origin}</strong> - - - - -{" "}
                    <strong> {selectedRoute.destination}</strong>
                  </div>
                </div>

                <div className="col-6">
                  <h5>
                    <strong>SEATS</strong>
                  </h5>
                </div>
                <div className=" col-6">
                  {JSON.parse(localStorage.getItem("selectedSeat")) !== null
                    ? JSON.parse(localStorage.getItem("selectedSeat")).length
                    : 0}
                </div>
                <div className="p-1">
                  <hr />
                </div>

                {/* <div className=" col-6">ADULTS :</div>
              <div className=" col-6"> 4 </div> */}
                {/*
              <div className=" col-6">
                CHILDREN<small>(Age under 3)</small> :
              </div>
              <div className=" col-6"> 4 </div> */}

                <div className=" col-6">
                  <h5>
                    <strong>TOTAL AMOUNT</strong>
                  </h5>
                </div>
                <div className=" col-6"> {totalFareCalculate()} </div>

                <div className="col-12 mt-2">
                  <div
                    className="btn btn-primary"
                    onClick={handleContinueBooking}
                    style={{ backgroundColor: "#6C63FF", border: "none" }}
                  >
                    CONTINUE BOOKING{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default BookSeat;
