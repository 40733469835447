import React, { Component } from "react";
import CustomModal from "../../../../components/WebsiteComponents/CustomModal";
import { axiosGet, axiosPost } from "../../../../utils/AxiosApi";
import { URL, moduleName } from "../../../../utils/Constants";
import { displayConfirmDeleteAlert, displayErrorAlert, DISPLAYMESSAGE } from "../../../../utils/DisplayMessage";
import CreateAdds from "./CreateAdds";
import { checkRolePermissions } from "../../../../utils/Helper";
import Pagination from "react-js-pagination";
import swal from "sweetalert";

export class Advertisements extends Component {
  state = {
    adData: [],
    modalOpen: false,
    editMode: false,
    updatePermission: false,
    deletePermission: false,
    current_page: 1,
    totalItemsCount: 0,
    paginated_value: 10,
    loader: false,
  };

  toggleModal = () => {
    this.setState({
      modalOpen: !this.state.modalOpen,
    });
  };
  handleModal = () => {
    this.setState({
      modalOpen: true,
      editMode: false,
    });
  };

  getAllAdds = (pageNumber, paginated_value) => {
    let param = {
      page: pageNumber ? pageNumber : 1,
      paginated_value: paginated_value ? paginated_value : 10,
    }
    axiosPost(URL.getAllAdds,
      param,
      (response) => {
        if (response.status === 200) {
          this.setState({
            adData: response?.data?.data?.items,
            totalItemsCount: response.data?.data?.totalData,
            current_page: pageNumber
          });
        }
      });
  };

  componentDidMount() {
    this.permissions();
  }

  editClick = (editData) => {
    this.setState({
      editData: editData,
      modalOpen: true,
      editMode: true,
    });
  };

  deleteAd = (e, id) => {
    let params = { id: id };
    displayConfirmDeleteAlert(params, this.handleDelete);
  }

  handleDelete = (params) => {
    this.setState({ loader: true }, () => {
      axiosGet(
        URL.deleteAdd + "/" + params.id,
        (response) => {
          if (response.status === 200) {
            swal("Success", DISPLAYMESSAGE.deleteSuccess, "success");
            this.setState({ spinner: false, loader: false });
            this.getAllAdds();
          }
        },
        (err) => {
          displayErrorAlert(err);
          this.setState({ spinner: false, loader: false });
        }
      );
    });
  };

  handleChangeToggle = (e, item) => {
    const { name, checked } = e.target;

    let formData = new FormData();

    let data = {
      display: checked,
    };

    formData.append("jsonData", JSON.stringify(data));

    axiosPost(
      URL.updateAdd + item.id,
      formData,
      (response) => {
        if (response.status === 200) {
          this.setState({ loader: false });
          this.getAllAdds();
        }
      },
      (err) => {
        displayErrorAlert(err);
      }
    );
  };

  permissions = () => {
    this.setState(
      {
        deletePermission: checkRolePermissions(
          "delete",
          moduleName.advertisement
        ),
        updatePermission: checkRolePermissions(
          "update",
          moduleName.advertisement
        ),
      },
      function () {
        this.getAllAdds();
      }
    );
  };

  handlePaginatedValueChange = (value) => {
    let valuePage = parseInt(value);
    this.setState({ paginated_value: valuePage }, () => {
      this.getAllAdds(1, valuePage);
    });
  };

  render() {
    const { current_page, paginated_value, totalItemsCount } = this.state;

    return (
      <div>
        <div className="dashboardHeader">
          <strong>Advertisements</strong>
        </div>
        <div className="container py-2">
          <div className="py-2">
            {this.state.adData?.length > 1
              ? ""
              : checkRolePermissions("create", moduleName.advertisement) && (
                <button
                  className="btn btn-primary"
                  onClick={this.handleModal}
                >
                  Add Data
                </button>
              )}
          </div>
          <div className="table-responsive d-flex flex-column justify-content-between" style={{ height: "100vh" }}>
            <table className="table table-bordered table-striped mb-0 text-center">
              <thead>
                <tr>
                  <th>S.N.</th>
                  <th>Title</th>
                  <th>Description</th>
                  <th>Display Location</th>
                  <th>Display</th>
                  <th>Link</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {this.state.adData && this.state.adData?.length > 0 ?
                  this.state.adData?.map((ad, idx) => (
                    <tr>
                      <td>{idx + 1}</td>
                      <td>{ad.name}</td>
                      <td>{ad.description}</td>
                      <td>{ad.type}</td>

                      <td>
                        {" "}
                        <label className="switch mt-2">
                          <input
                            type="checkbox"
                            onChange={(e) => this.handleChangeToggle(e, ad)}
                            checked={ad.display}
                            name="enabled"
                          />
                          <span className="slider round"></span>
                        </label>
                      </td>

                      <td>{ad.link}</td>
                      <td>
                        {this.state.updatePermission && (
                          <>
                            <button
                              className="btn btn-primary m-1"
                              onClick={() => this.editClick(ad)}
                            >
                              Edit
                            </button>
                            <button
                              className="btn btn-primary m-1"
                              onClick={(e) => this.deleteAd(e, ad.id)}
                            >
                              Delete
                            </button>
                          </>
                        )}
                        {/* <button>Delete</button> */}
                      </td>
                    </tr>
                  )) :
                  (
                    <>
                      <tr>
                        <td colSpan={7}>No Data</td>
                      </tr>
                    </>)}
              </tbody>
            </table>
            {this.state.adData?.length <= 0 ? (
              <></>
            ) : (
              <>
                <div className="paginationContainer">
                  <Pagination
                    activePage={current_page}
                    itemsCountPerPage={paginated_value}
                    totalItemsCount={totalItemsCount}
                    onChange={(pageNumber) => this.getAllAdds(pageNumber, paginated_value)}
                    itemClass="page-item"
                    linkClass="page-link"
                    firstPageText="First"
                    lastPageText="Last"
                  />
                  {/* <div className="paginatoion_paginated_value ml-3">
                    <select
                      className="form-control"
                      value={this.state.paginated_value}
                      onChange={(e) => this.handlePaginatedValueChange(e.target.value)}
                    >
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={30}>30</option>
                      <option value={40}>40</option>
                      <option value={50}>50</option>
                    </select>
                  </ div> */}
                </div>

              </>
            )}
          </div>

        </div>

        <CustomModal
          modal={this.state.modalOpen}
          modalBody={
            <CreateAdds
              getAllAdds={this.getAllAdds}
              editMode={this.state.editMode}
              editData={this.state.editData}
              toggleModal={this.toggleModal}
            />
          }
          modalHeader={
            this.state.editMode ? "Edit Advertisement" : "Add Advertisement"
          }
          size="lg"
          toggleModal={this.toggleModal}
        ></CustomModal>
      </div>
    );
  }
}

export default Advertisements;
