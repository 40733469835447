import React, { Component } from "react";
import { Spinner } from "reactstrap";
import swal from "sweetalert";
import CustomModal from "../../../../components/WebsiteComponents/CustomModal";
import { axiosGet, axiosPost } from "../../../../utils/AxiosApi";
import { moduleName, URL } from "../../../../utils/Constants";
import {
  displayConfirmDeleteAlert,
  displayErrorAlert,
  DISPLAYMESSAGE,
} from "../../../../utils/DisplayMessage";
import {
  checkRolePermissions,
  getRolesFromStorage,
} from "../../../../utils/Helper";
import CreateBusSchedule from "./CreateBusSchedule";
import Pagination from "react-js-pagination";

class ScheduleSetting extends Component {
  state = {
    buses: [],
    createModal: false,
    deletePermission: false,
    updatePermission: false,

    loader: false,
    schedules: [],
    editMode: false,
    updateReqData: "",
    current_page: 1,
    totalItemsCount: 0,
    paginated_value: 10,
  };

  componentDidMount() {
    this.getBuses();
    this.getBusSchedules();
    this.permissions();
  }

  getBuses = () => {
    let param = {};
    axiosPost(
      URL.buses + "/get-buses", param,
      (response) => {
        if (response.status === 200) {
          this.setState({
            buses: response.data.data.items,
          });
        }
      },
      (err) => {
        displayErrorAlert(err);
      }
    );
  };

  getBusSchedules = (pageNumber, paginated_value) => {
    let param = {
      page: pageNumber ? pageNumber : 1,
      paginated_value: paginated_value ? paginated_value : 10,

    }
    this.setState({ loader: true, schedules: [] }, function () {
      axiosPost(
        URL.getAllBusSchedules, param,
        (response) => {
          if (response.status === 200) {
            let datum = response.data.data.items;
            //   newData = [];
            // datum.forEach((el) => {
            //   if (el.schedule_setting && el.schedule_setting.length > 0) {
            //     newData.push(el);
            //   }
            // });
            this.setState({
              current_page: pageNumber,
              schedules: datum,
              totalItemsCount: response.data.data.totalData,
              loader: false,
            });
          }
        },
        (err) => {
          displayErrorAlert(err);
          this.setState({ loader: false });
        }
      );
    });
  };

  handleCreateModal = () => {
    this.setState({
      createModal: !this.state.createModal,
      editMode: false,
      updateReqData: "",
    });
  };

  handleDeleteBtnClick = (data) => {
    debugger
    let params = { bus_id: data.bus_id, route_id: data.route_id };
    displayConfirmDeleteAlert(params, this.handleDelete);
  };

  handleDelete = (params) => {
    this.setState({
      loader: true,
    });
    axiosPost(
      URL.deleteBusScheduling,
      params,
      (response) => {
        if (response.status === 200) {
          swal("Success", DISPLAYMESSAGE.deleteSuccess, "success");
          this.getBusSchedules();
          this.setState({
            loader: false,
          });
        }
      },
      (err) => {
        displayErrorAlert(err);
        this.setState({ loader: false });
      }
    );
  };

  permissions = () => {
    this.setState({
      deletePermission: checkRolePermissions(
        "delete",
        moduleName.scheduleSetting
      ),
      updatePermission: checkRolePermissions(
        "update",
        moduleName.scheduleSetting
      ),
    });
  };

  handleUpdateBtnClick = (item) => {
    this.setState({
      editMode: true,
      createModal: !this.state.createModal,
      updateReqData: item,
    });
  };

  handleClickSchedule = (data) => {
    let param = {
      schedule_setting_id: data.id,
      days: data.days,
      auto_schedule: true,
    };

    this.setState({ loader: true }, () => {
      axiosPost(
        URL.updateBusScheduleSetting,
        param,
        (response) => {
          if (response.status === 200) {
            swal("Success", DISPLAYMESSAGE.updateSuccess, "success");
            this.getBusSchedules();
          }
        },
        (err) => {
          displayErrorAlert(err);
          this.setState({ loader: false });
        }
      );
    });
  };

  handlePaginatedValueChange = (value) => {
    let valuePage = parseInt(value);
    this.setState({ paginated_value: valuePage }, () => {
      this.getBusSchedules(1, valuePage);
    });
  };

  render() {
    const { current_page, paginated_value, totalItemsCount } = this.state;
    const roles = getRolesFromStorage();

    return (
      <>
        <div className="dashboardHeader">
          <strong>BUS SCHEDULE SETTING</strong>
        </div>
        <div className="container-fluid h-100">
          <div className="row mt-2">
            <div className="col">
              <button
                className="btn btn-success"
                onClick={this.handleCreateModal}
              >
                Add Bus Schedule
              </button>
            </div>
          </div>
          <div className="row mt-2 h-100">
            <div className="col d-flex align-items-center justify-content-between flex-column">
              <div className="table-responsive w-100">
                <table className="table table-bordered text-center">
                  <thead>
                    <tr>
                      <th width="50px">S.N</th>
                      <th>Bus Name</th>
                      <th>Route</th>
                      <th>Schedule Setting</th>
                      <th>Duration</th>
                      <th>Schedule Day</th>
                      {this.state.deletePermission ? <th>Options</th> : null}
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.schedules?.data?.length > 0 ? (
                      this.state.schedules?.data?.map((item, idx) => {
                        return (
                          <React.Fragment>
                            <tr key={idx}>
                              <td rowSpan={item.schedule_setting.length}>
                                {idx + 1}
                              </td>
                              <td rowSpan={item.schedule_setting.length}>
                                {item.name}
                                <br />
                                {roles[0] === "Network Admin" && (
                                  <span className="badge rounded-pill bg-info text-dark text-white">
                                    {item.travel_agency.name}
                                  </span>
                                )}
                              </td>
                              <td rowSpan={1}>
                                {
                                  item.schedule_setting[0]?.route?.origin_city
                                    .name
                                }{" "}
                                -{" "}
                                {
                                  item.schedule_setting[0]?.route
                                    ?.destination_city.name
                                }
                              </td>
                              <td>
                                {item.schedule_setting[0]?.auto_schedule ? (
                                  "Automatic"
                                ) : (
                                  <>
                                    Manual Schedule <br />
                                    <button
                                      className="btn btn-success m-2"
                                      onClick={() =>
                                        this.handleClickSchedule(
                                          item.schedule_setting[0]
                                        )
                                      }
                                    >
                                      <i class="fa-solid fa-play"></i>
                                    </button>
                                  </>
                                )}
                              </td>
                              <td>
                                From
                                <br />
                                {item.schedule_setting[0]?.start_date ||
                                  "--"}{" "}
                                <br />
                                To <br />
                                {item.schedule_setting[0]?.end_date || "--"}
                              </td>
                              <td rowSpan={1}>
                                {item.schedule_setting[0]?.days.map(
                                  (el, index) => {
                                    return (
                                      <React.Fragment key={index}>
                                        <span key={index}>{el.day}</span>
                                        <br></br>
                                      </React.Fragment>
                                    );
                                  }
                                )}
                              </td>
                              {this.state.deletePermission ? (
                                <td rowSpan={1}>
                                  <button
                                    className="btn btn-danger m-2"
                                    onClick={() =>
                                      this.handleDeleteBtnClick(
                                        item.schedule_setting[0]
                                      )
                                    }
                                  >
                                    <i class="fa-solid fa-trash"></i>
                                  </button>
                                  <button
                                    className="btn btn-info m-2"
                                    onClick={() =>
                                      this.handleUpdateBtnClick(
                                        item.schedule_setting[0]
                                      )
                                    }
                                  >
                                    <i class="fa-solid fa-pen-to-square"></i>
                                  </button>
                                </td>
                              ) : null}
                            </tr>
                            {item.schedule_setting.map((setting, idx) => {
                              if (idx > 0) {
                                return (
                                  <tr key={idx}>
                                    <td rowSpan={1}>
                                      {setting.route.origin_city.name} -{" "}
                                      {setting.route.destination_city.name}
                                    </td>
                                    <td>
                                      {setting.auto_schedule ? (
                                        "Automatic"
                                      ) : (
                                        <>
                                          Manual Schedule <br />
                                          <button
                                            className="btn btn-success m-2"
                                            onClick={() =>
                                              this.handleClickSchedule(setting)
                                            }
                                          >
                                            <i class="fa-solid fa-play"></i>
                                          </button>
                                        </>
                                      )}
                                    </td>
                                    <td>
                                      From
                                      <br />
                                      {setting.start_date || "--"} <br />
                                      To
                                      <br />
                                      {setting.end_date || "--"}
                                    </td>

                                    <td rowSpan={1}>
                                      {setting.days.map((el, index) => {
                                        return (
                                          <React.Fragment key={index}>
                                            <span key={index}>{el.day}</span>
                                            <br></br>
                                          </React.Fragment>
                                        );
                                      })}
                                    </td>

                                    <td rowSpan={1}>
                                      {this.state.deletePermission && (
                                        <button
                                          className="btn btn-danger m-2"
                                          onClick={() =>
                                            this.handleDeleteBtnClick(setting)
                                          }
                                        >
                                          <i class="fa-solid fa-trash"></i>
                                        </button>
                                      )}
                                      {this.state.updatePermission && (
                                        <button
                                          className="btn btn-info m-2"
                                          onClick={() =>
                                            this.handleUpdateBtnClick(setting)
                                          }
                                        >
                                          <i class="fa-solid fa-pen-to-square"></i>
                                        </button>
                                      )}
                                    </td>
                                  </tr>
                                );
                              }
                            })}
                          </React.Fragment>
                        );
                      })
                    ) : (
                      <tr>
                        <td
                          className="text-center"
                          colSpan={
                            this.state.updatePermission ||
                              this.state.deletePermission
                              ? 6
                              : 5
                          }
                        >
                          {this.state.loader ? (
                            <>
                              <Spinner></Spinner>
                              <br></br>Loading Data...
                            </>
                          ) : (
                            "No bus scheduling done yet"
                          )}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {this.state.totalItemsCount <= 0 ? (
                <></>
              ) : (
                <>
                  <div className="paginationContainer">
                    <Pagination
                      activePage={current_page}
                      itemsCountPerPage={paginated_value}
                      totalItemsCount={totalItemsCount}
                      onChange={(pageNumber) => this.getBusSchedules(pageNumber, paginated_value)}
                      itemClass="page-item"
                      linkClass="page-link"
                      firstPageText="First"
                      lastPageText="Last"
                    />
                    <div className="paginatoion_paginated_value ml-3">
                      <select
                        className="form-control"
                        value={this.state.paginated_value}
                        onChange={(e) => this.handlePaginatedValueChange(e.target.value)}
                      >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={30}>30</option>
                        <option value={40}>40</option>
                        <option value={50}>50</option>
                      </select>
                    </ div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <CustomModal
          modal={this.state.createModal}
          modalBody={
            <CreateBusSchedule
              editMode={this.state.editMode}
              buses={this.state.buses}
              handleCreateModal={this.handleCreateModal}
              updateReqData={this.state.updateReqData}
              getBusSchedules={this.getBusSchedules}
            />
          }
          modalHeader={
            this.state.editMode ? "Edit Bus Schedule" : "Create Bus Schedule"
          }
          size="xl"
          toggleModal={this.handleCreateModal}
        />
      </>
    );
  }
}

export default ScheduleSetting;
